import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';

import animationStyles from '../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import {
  clearWizardState,
  createPlacementTestSessionRequest,
  updatePlacementTestSessionRequest
} from '../../redux/actions/placementTestOnboardingWizard';
import { closePlacementTestOnboardingWizard } from '../../redux/actions/hubUi';
import { closeForm } from '../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../language/withLocalizedContent';

function PlacementTestOnboardingWizardFooter({
  placementTestSessionId,
  placementTestSessionNameValue,
  placementTestSessionIdCreated,
  editedPlacementTest,
  orgId,
  userId,
  placementTestSessionNamePending,
  closeWizardAction,
  clearWizardStateAction,
  clearPlacementTestSessionCreateStateAction,
  showResultsToStudents,
  updatePlacementTestSessionRequestAction,
  createPlacementTestSessionRequestAction,
  localizedContent: { hubGlossary: hubContent }
}) {
  const { step, nextStep, isPrimaryButtonDisabled, isLastStep } = useWizard();

  const loading = placementTestSessionNamePending ? animationStyles.loading : '';

  const sessionId = placementTestSessionId || placementTestSessionIdCreated;

  const handleShowResultStep = () => {
    updatePlacementTestSessionRequestAction({
      orgId,
      sessionId,
      placementTestSessionNameValue,
      placementTest: { ...editedPlacementTest, showResultsToStudents, status: 'DRAFT' }
    });
  };

  const createPlacementTestSession = () => {
    if (!sessionId) {
      createPlacementTestSessionRequestAction({
        userId,
        orgId,
        placementTestSessionNameValue,
        placementTest: { ...editedPlacementTest, showResultsToStudents, status: 'DRAFT' }
      });
    } else {
      nextStep();
    }
  };

  const handleCancel = () => {
    closeWizardAction();
    clearWizardStateAction();
    clearPlacementTestSessionCreateStateAction();
  };

  const handleStep = () => {
    if (step === 6) {
      handleShowResultStep();
    } else if (step === 3) {
      createPlacementTestSession();
    } else if (isLastStep) {
      closeWizardAction();
      clearWizardStateAction();
      clearPlacementTestSessionCreateStateAction();
    } else {
      nextStep();
    }
  };

  const setPrimaryButtonLabel = () => {
    if (step === 6) {
      return hubContent.finish;
    }

    if (isLastStep) {
      return hubContent.done_button;
    }

    return hubContent.next;
  };

  return (
    <Footer
      primaryButtonAction={handleStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      secondaryButtonAction={step < 2 && handleCancel}
      secondaryButtonLabel={step < 2 ? hubContent.cancel : ''}
      primaryButtonAttributes={{
        disabled: isPrimaryButtonDisabled,
        loading,
        dataAttributes: {
          testid: isLastStep
            ? 'ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_DONE_BUTTON'
            : 'ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_NEXT_BUTTON'
        }
      }}
      secondaryButtonAttributes={{
        dataAttributes: { testid: 'ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_CANCEL_BUTTON' }
      }}
    />
  );
}

PlacementTestOnboardingWizardFooter.propTypes = {
  editedPlacementTest: PropTypes.object,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  placementTestSessionNameValue: PropTypes.string,
  placementTestSessionIdCreated: PropTypes.string,
  createPlacementTestSessionRequestAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  placementTestSessionNamePending: PropTypes.bool,
  placementTestSessionId: PropTypes.string,
  showResultsToStudents: PropTypes.bool,
  updatePlacementTestSessionRequestAction: PropTypes.func,
  closeWizardAction: PropTypes.func.isRequired,
  clearWizardStateAction: PropTypes.func.isRequired,
  clearPlacementTestSessionCreateStateAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(
    ({ placementTestSessionCreate, placementOnboardingWizard, identity }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionNamePending: placementOnboardingWizard.placementTestSessionNamePending,
      userId: identity.userId,
      orgId: identity.currentOrganisationId,
      editedPlacementTest: placementTestSessionCreate.editedPlacementTest,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      showResultsToStudents: placementTestSessionCreate.placementTest.showResultsToStudents
    }),
    {
      clearWizardStateAction: clearWizardState,
      createPlacementTestSessionRequestAction: createPlacementTestSessionRequest,
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest,
      closeWizardAction: closePlacementTestOnboardingWizard,
      clearPlacementTestSessionCreateStateAction: closeForm
    }
  )
)(PlacementTestOnboardingWizardFooter);
