import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from 'react-use-wizard';
// Utils
import animationTimeout from '../animationUtils/animationTimeout';
import ErrorStrip from '../../ErrorStrip/ErrorStrip';
import cmsContent from '../../../utils/cmsContent.js';
import userRoles from '../../../globals/userRoles';
import preventBodyScroll from '../../../utils/dom/preventBodyScroll';
// Components
import { GLYPHS } from '../../SVGIcon/SVGIcon';
import Button, { buttonTypes } from '../../Button/Button';
import ClassroomNameInput from '../../../routes/MySchool/tabs/ClassroomsTab/panels/AddClassroom/ClassroomNameInput';
// Redux
import { clearWizardState, setClassNameRequest } from '../../../redux/actions/classOnboardingWizard';
import { setActiveClassId } from '../../../redux/reducers/classroomPage';
import { storeClassrooms } from '../../../redux/reducers/data/classrooms';
import { initialiseInstance } from '../../../redux/reducers/data/search.reducer';
import { closeForm } from '../../../redux/reducers/classroomCreate.js';
import { closeOnboardingWizard } from '../../../redux/actions/hubUi.js';
// Styles
import classStyles from './CreateAClass.scss';
import wizardStyles from '../ClassOnboardingWizard.scss';
import animationStyles from '../animationUtils/animationStyles.scss';

function CreateAClass({
  closeWizardWithWarningPopup,
  classNameValue,
  classroomNameValidationPending,
  classNamePending,
  classroomNameValidationIsInvalid,
  classroomNameValidationMessage,
  classCreationError,
  classCreationLoading,
  classCreated,
  userId,
  setClassNameRequestAction,
  hubContent,
  orgId,
  classId = '',
  setClassPageIdAction,
  storeClassroomsAction,
  initialiseInstanceAction,
  isOpen,
  closeWizardAction,
  clearWizardStateAction,
  clearClassroomCreateStateAction
}) {
  const contentManagementSystem = cmsContent.classroomState || {};
  const { nextStep, handleStep, isLoading, isFirstStep } = useWizard();
  const [stepOut, setStepOut] = useState(false);

  const loading = classroomNameValidationPending || classNamePending ? animationStyles.loading : '';
  const fadeOut = stepOut ? animationStyles.fadeOut : '';
  const emptyInput = !classNameValue;
  const invalidInput =
    classroomNameValidationIsInvalid &&
    (classroomNameValidationMessage === contentManagementSystem.class_name_special_character_error ||
      classroomNameValidationMessage === contentManagementSystem.class_length_error ||
      classroomNameValidationMessage === contentManagementSystem.unable_to_check_class_name);

  handleStep(async () => {
    setStepOut(true);
    await animationTimeout();
  });

  // Update the classroom related redux store in order to have all the necessary information for AddStudents panel in the next step
  const updateClassContext = () => {
    const createdClass = {
      [classId]: {
        teacherIdList: [userId],
        amountOfTeachers: 1,
        amountOfLearners: 0,
        studentIdList: [],
        archived: false,
        name: classNameValue,
        orgId
      }
    };
    storeClassroomsAction(createdClass);
    setClassPageIdAction(orgId, classId);
    initialiseInstanceAction('classStudents', false, {
      orgId,
      classId,
      active: true,
      invited: true,
      roles: [userRoles.LEARNER]
    });
    initialiseInstanceAction('profileClasses', false, { active: true, orgId, userId });
  };

  useEffect(() => {
    if (classCreated) {
      updateClassContext();
      nextStep();
    }
  }, [classCreated]);

  useEffect(() => {
    preventBodyScroll(isOpen);
  });

  return (
    <>
      <div
        data-testid="ONBOARDING_WIZARD_CREATE_A_CLASS_CONTAINER"
        className={`${wizardStyles.step} ${classStyles.container} ${animationStyles.step} ${fadeOut}`}
      >
        <div className={`${wizardStyles.stepContent} ${classStyles.classCreateContainer}`}>
          <div className={classStyles.classCreateColumn}>
            {classCreationError && (
              <ErrorStrip
                glyphCustomClass={classStyles.errorGlyph}
                errorCustomClass={classStyles.errorContainer}
                glyph={GLYPHS.ICON_VALIDATION_CROSS_ONBOARDING}
                message={hubContent.error_creating_class}
              />
            )}
            <h1>{hubContent.create_class_title}</h1>
            <ClassroomNameInput
              isInputDisabled={classCreationLoading}
              dataTestId="ONBOARDING_WIZARD_CLASS_NAME_INPUT"
            />
          </div>
          <div className={wizardStyles.imageContainer}>
            <img src="/media/hub/class-onboarding/illustration-class-students.svg" alt="Class students" />
          </div>
        </div>
      </div>

      <footer className={wizardStyles.footer}>
        <Button
          customClassName={`${wizardStyles.cancelLink}`}
          dataTestId="ONBOARDING_WIZARD_CREATE_CLASS_CANCEL_BUTTON"
          text={hubContent.cancel}
          type={buttonTypes.CANCEL}
          onClick={() => {
            if (isFirstStep) {
              closeWizardAction();
              clearWizardStateAction();
              clearClassroomCreateStateAction();
            } else {
              closeWizardWithWarningPopup();
            }
          }}
        />
        <Button
          customClassName={`${animationStyles.nextButton} ${loading}`}
          dataTestId="ONBOARDING_WIZARD_CREATE_CLASS_NEXT_BUTTON"
          text={!loading ? hubContent.next : ''}
          disabled={!!loading || isLoading || emptyInput || invalidInput}
          onClick={() => {
            setClassNameRequestAction({ userId, orgId, classNameValue });
          }}
        />
      </footer>
    </>
  );
}

CreateAClass.propTypes = {
  closeWizardWithWarningPopup: PropTypes.func.isRequired,
  classNameValue: PropTypes.string,
  classroomNameValidationPending: PropTypes.bool,
  classroomNameValidationIsInvalid: PropTypes.bool,
  classNamePending: PropTypes.bool,
  classroomNameValidationMessage: PropTypes.string,
  classCreated: PropTypes.bool,
  classCreationError: PropTypes.bool,
  classCreationLoading: PropTypes.bool,
  userId: PropTypes.string,
  setClassNameRequestAction: PropTypes.func,
  orgId: PropTypes.string,
  hubContent: PropTypes.object.isRequired,
  classId: PropTypes.string,
  setClassPageIdAction: PropTypes.func,
  storeClassroomsAction: PropTypes.func,
  initialiseInstanceAction: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  closeWizardAction: PropTypes.func.isRequired,
  clearWizardStateAction: PropTypes.func.isRequired,
  clearClassroomCreateStateAction: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ classOnboardingWizard, classroomCreate, identity }) => ({
      classNameValue: classroomCreate.classroomNameValue,
      classNameValidationIsWarning: classroomCreate.classroomNameValidationIsWarning,
      classroomNameValidationPending: classroomCreate.classroomNameValidationPending,
      classNamePending: classOnboardingWizard.classNamePending,
      classroomNameValidationIsInvalid: classroomCreate.classroomNameValidationIsInvalid,
      classroomNameValidationIsValid: classroomCreate.classroomNameValidationIsValid,
      classroomNameValidationMessage: classroomCreate.classroomNameValidationMessage,
      classCreated: classOnboardingWizard.classCreated,
      classCreationError: classOnboardingWizard.classCreationError,
      classCreationLoading: classOnboardingWizard.classCreationLoading,
      classId: classOnboardingWizard.classId,
      userId: identity.userId,
      orgId: identity.currentOrganisationId,
      userRole: identity.role
    }),
    {
      setClassNameRequestAction: setClassNameRequest,
      setClassPageIdAction: setActiveClassId,
      storeClassroomsAction: storeClassrooms,
      initialiseInstanceAction: initialiseInstance,
      clearWizardStateAction: clearWizardState,
      clearClassroomCreateStateAction: closeForm,
      closeWizardAction: closeOnboardingWizard
    }
  )
)(CreateAClass);
