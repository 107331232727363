import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import TextInput from '@oup/shared-front-end/src/components/TextInput';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import Card from '../Card/Card.js';
// Redux
import {
  isEmailTakenRequest,
  resetEditCurrentUserDetailsState,
  editCurrentUserDetailsRequest
} from '../../redux/actions/editCurrentUserDetails.js';
// Services
import appSettings from '../../globals/appSettings.js';
// Styles
import colors from '../../globals/colors.js';
import styles from './EditUserAccount.scss';

function EditUserAccountForm({
  hubContent,
  verifiedEmail,
  isEmailTaken,
  isEmailTakenRequestAction,
  resetEditCurrentUserDetailsStateAction,
  editCurrentUserDetailsRequestAction
}) {
  const [emailValue, setEmailValue] = useState('');
  const [isEmailFormatValid, setIsEmailFormatValid] = useState(undefined);
  const [confirmedEmailValue, setConfirmedEmailValue] = useState('');
  const [isConfirmedEmailValid, setIsConfirmedEmailValid] = useState(undefined);

  const onChangeEmail = value => {
    setEmailValue(value);
    setIsEmailFormatValid(appSettings.EMAIL_REGEX.test(value));

    if (verifiedEmail && isEmailTaken) {
      resetEditCurrentUserDetailsStateAction();
    }
  };

  const checkIsConfirmedEmailValid = (email, confirmedEmail) => email.toLowerCase() === confirmedEmail.toLowerCase();

  const onChangeConfirmEmail = value => {
    setConfirmedEmailValue(value);
    const isConfirmedEmailCorrect = checkIsConfirmedEmailValid(emailValue, value);
    setIsConfirmedEmailValid(isConfirmedEmailCorrect);
  };

  const onBlurEmail = () => {
    const isConfirmedEmailCorrect = checkIsConfirmedEmailValid(emailValue, confirmedEmailValue);
    setIsConfirmedEmailValid(confirmedEmailValue && isConfirmedEmailCorrect);

    if (emailValue && isEmailFormatValid) {
      isEmailTakenRequestAction({ email: emailValue });
    }
  };

  const getValidationMessage = () => {
    if (emailValue && !isEmailFormatValid) {
      return hubContent.please_enter_a_valid_email;
    }
    if (emailValue && isEmailTaken) {
      return hubContent.existing_email_error_message;
    }
    return '';
  };

  const onSubmit = () => {
    editCurrentUserDetailsRequestAction({ newUserEmail: emailValue });
  };

  const isButtonDisabled =
    !isEmailFormatValid || !checkIsConfirmedEmailValid(emailValue, confirmedEmailValue) || isEmailTaken;

  return (
    <div className={styles.editAccountFormContainer}>
      <p className={styles.formTitle}>{hubContent.enter_an_email_address_to_continue}</p>

      <div className={styles.cardsContainer}>
        <Card headingText={hubContent.my_details} headingColor={colors.REGISTER}>
          <div className={styles.inputContainer}>
            <TextInput
              id="email"
              type="email"
              label={hubContent.email_address}
              labelSub={hubContent.email_address_subtitle}
              placeholder={hubContent.enter_your_email_address}
              value={emailValue}
              onChange={onChangeEmail}
              onBlur={onBlurEmail}
              isValid={isEmailFormatValid && !isEmailTaken}
              validationMessage={getValidationMessage()}
            />
          </div>
          <div className={styles.inputContainer}>
            <TextInput
              id="email"
              type="email"
              label={hubContent.confirm_email_address}
              placeholder={hubContent.enter_your_email_address_again}
              value={confirmedEmailValue}
              onChange={onChangeConfirmEmail}
              isValid={isConfirmedEmailValid}
              validationMessage={
                confirmedEmailValue && !isConfirmedEmailValid ? hubContent.these_email_addresses_do_not_match : ''
              }
            />
          </div>
        </Card>
        <Card headingText={hubContent.help_and_support} headingColor={colors.SUPPORT_ICON}>
          <div className={styles.linkContainer}>
            <p className={styles.boldText}>{hubContent.need_help}</p>
            <p>
              <span>{hubContent.contact}</span>
              <TextLink to={hubContent.contact_us_link} underline target="_blank">
                <span className={styles.boldText}>{hubContent.customer_support}</span>
              </TextLink>
            </p>
          </div>
        </Card>
      </div>

      <Button
        id="updateDetailsButton"
        type="submit"
        text={hubContent.update_details}
        onClick={onSubmit}
        disabled={isButtonDisabled}
      />
    </div>
  );
}

EditUserAccountForm.propTypes = {
  hubContent: PropTypes.object.isRequired,
  isEmailTakenRequestAction: PropTypes.func.isRequired,
  verifiedEmail: PropTypes.string,
  isEmailTaken: PropTypes.bool.isRequired,
  resetEditCurrentUserDetailsStateAction: PropTypes.func.isRequired,
  editCurrentUserDetailsRequestAction: PropTypes.func.isRequired
};

export default connect(
  ({ editCurrentUserDetails }) => ({
    isEmailTaken: editCurrentUserDetails.isEmailTaken,
    verifiedEmail: editCurrentUserDetails.email
  }),
  {
    isEmailTakenRequestAction: isEmailTakenRequest,
    resetEditCurrentUserDetailsStateAction: resetEditCurrentUserDetailsState,
    editCurrentUserDetailsRequestAction: editCurrentUserDetailsRequest
  }
)(EditUserAccountForm);
