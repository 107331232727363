import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Wizard } from 'react-use-wizard';
import SelfRegisteredUserOnboardingWizardStaffRoleChoosesClassesAndCourses from './SelfRegisteredUserOnboardingWizardStaffRoleChoosesClassesAndCourses/SelfRegisteredUserOnboardingWizardStaffRoleChoosesClassesAndCourses.js';
import SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails from './SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails/SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails.js';
import SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest from './SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest/SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest.js';
import SelfRegisteredUserOnboardingOrganizationSetupWizardHeader from './SelfRegisteredUserOnboardingOrganizationSetupWizardHeader.js';
import wizardStyles from './SelfRegisteredUserOnboardingWizard.scss';
import SelfRegisteredUserOnboardingWizardWarningModal from './SelfRegisteredUserOnboardingWizardWarningModal/SelfRegisteredUserOnboardingWizardWarningModal.js';
import SelfRegisteredUserOnboardingWizardAddStaff from './SelfRegisteredUserOnboardingWizardAddStaff/SelfRegisteredUserOnboardingWizardAddStaff.js';
import SelfRegisteredUserOnboardingWizardSuccess from './SelfRegisteredUserOnboardingWizardSuccess/SelfRegisteredUserOnboardingWizardSuccess';
import SelfRegisteredUserCreateFirstPlacementTestOrClassModal from './SelfRegisteredUserCreateFirstPlacementTestOrClassModal/SelfRegisteredUserCreateFirstPlacementTestOrClassModal.js';
import { showSelfSelectRoleModal } from '../../redux/actions/hubUi.js';
import { HubLayoutConstants } from '../../globals/hubConstants.js';

function SelfRegisteredUserOnboardingOrganizationSetupWizard({ secondWizardType, showSelfSelectRoleModalAction }) {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isOpenSelfRegisteredUserModal, setIsOpenSelfRegisteredUserModal] = useState(false);
  const {
    PATH_NAMES: { DASHBOARD_PATH }
  } = HubLayoutConstants;

  return (
    <div
      className={wizardStyles.wizardContainer}
      data-testid="SELF_REGISTERED_USER_ONBOARDING_ORGANIZATION_SETUP_WIZARD_CONTAINER"
    >
      <Wizard
        header={
          <SelfRegisteredUserOnboardingOrganizationSetupWizardHeader
            secondWizardType={secondWizardType}
            setIsWarningModalOpen={setIsWarningModalOpen}
            setIsOpenSelfRegisteredUserModal={setIsOpenSelfRegisteredUserModal}
          />
        }
      >
        {secondWizardType === 'class' ? (
          <SelfRegisteredUserOnboardingWizardStaffRoleChoosesClassesAndCourses />
        ) : (
          <SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest />
        )}
        <SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails branch={secondWizardType} />
        <SelfRegisteredUserOnboardingWizardAddStaff />
        <SelfRegisteredUserOnboardingWizardSuccess
          setIsOpenSelfRegisteredUserModal={setIsOpenSelfRegisteredUserModal}
        />
      </Wizard>
      <SelfRegisteredUserOnboardingWizardWarningModal
        isOpen={isWarningModalOpen}
        closeWarningModal={() => setIsWarningModalOpen(false)}
      />
      <SelfRegisteredUserCreateFirstPlacementTestOrClassModal
        isOpen={isOpenSelfRegisteredUserModal}
        onClose={() => {
          setIsOpenSelfRegisteredUserModal(false);
          showSelfSelectRoleModalAction(false);
        }}
        onNext={() => {
          // It is a temporary solution until we have the backend implemented on the organization creation side, so that there is continuity in the wizard
          window.location.href = DASHBOARD_PATH;
        }}
        wizardType={secondWizardType}
      />
    </div>
  );
}

SelfRegisteredUserOnboardingOrganizationSetupWizard.propTypes = {
  secondWizardType: PropTypes.string,
  showSelfSelectRoleModalAction: PropTypes.func
};

export default compose(
  connect(null, {
    showSelfSelectRoleModalAction: showSelfSelectRoleModal
  })
)(SelfRegisteredUserOnboardingOrganizationSetupWizard);
