import React from 'react';
import PropTypes from 'prop-types';
import { useWizard } from 'react-use-wizard';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';

import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import styles from '../SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest/SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingWizardStaffRoleChoosesClassesAndCourses({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent }
}) {
  const { nextStep } = useWizard();

  return (
    <>
      <div
        data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_STAFF__ROLE_CHOOSES_CLASSES_AND_COURSES_CONTAINER"
        className={wizardStyles.step}
      >
        <div className={styles.stepContent}>
          <h1>{content.staff_role_chooses_classes_and_courses_title}</h1>
          <h2>{content.staff_role_chooses_classes_and_courses_subtitle}</h2>
          <ol className={styles.organizationSetupSteps}>
            <li>
              <p>{content.staff_role_chooses_classes_and_courses_first_step_bold}</p>
              <p>{content.staff_role_chooses_classes_and_courses_first_step}</p>
            </li>
            <li>
              <p>{content.staff_role_chooses_classes_and_courses_second_step_bold}</p>
              <p>{content.staff_role_chooses_classes_and_courses_second_step}</p>
            </li>
            <li>
              <p>{content.staff_role_chooses_classes_and_courses_third_step_bold}</p>
              <p>{content.staff_role_chooses_classes_and_courses_third_step}</p>
            </li>
          </ol>
          <TextLink to="#" target="_self">
            {content.staff_role_chooses_classes_and_courses_learn_more_link_text}
          </TextLink>
        </div>
        <div className={wizardStyles.imageContainer}>
          <img
            src="/media/hub/class-onboarding/illustration-class-organizing.svg"
            alt="Illustration class organizing"
          />
        </div>
      </div>
      <footer className={wizardStyles.footer}>
        <Button
          dataAttributes={{ testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON' }}
          variant="filled"
          text={hubContent.next}
          disabled={false}
          onClick={() => nextStep()}
        />
      </footer>
    </>
  );
}

SelfRegisteredUserOnboardingWizardStaffRoleChoosesClassesAndCourses.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent(
  'selfRegisteredUserOnboardingWizard',
  'hubGlossary'
)(SelfRegisteredUserOnboardingWizardStaffRoleChoosesClassesAndCourses);
