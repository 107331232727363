import React from 'react';
import PropTypes from 'prop-types';
// Style
import styles from './PlaceholderClassTile.scss';

// Components
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Link from '../Link/Link';

function PlaceholderClassTile({ onClickFirstButton, firstButtonText, firstButtonTestHook }) {
  return (
    <Link className={styles.addClassroom} to="" onClick={onClickFirstButton} dataTestId={firstButtonTestHook}>
      <div className={styles.addContainer}>
        <span className={styles.addText}>{firstButtonText}</span>
        <div className={styles.addIcon}>
          <SVGIcon glyph={GLYPHS.ICON_PLUS} />
        </div>
      </div>
    </Link>
  );
}

export default PlaceholderClassTile;

PlaceholderClassTile.propTypes = {
  firstButtonText: PropTypes.string,
  onClickFirstButton: PropTypes.func,
  firstButtonTestHook: PropTypes.string
};
