import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from 'react-router-dom';
import pick from 'lodash.pick';
import { PLATFORMS } from '@oup/shared-node-browser/constants';
import { LTI_ACTIONS } from '../../globals/hubConstants';
import withLocalizedContent from '../../language/withLocalizedContent';
import { hubDownloadResource } from '../../redux/actions/hubResource';
import styles from '../../structure/HubLayout/HubLayout.scss';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ErrorView from '../../views/errors/ErrorView';
import ResourceFormHidden from '../../structure/HubProductLayout/Resources/ResourceFormHidden';

// it was built to support oppening/redirecting to VST products only for now
const acceptedResourceTypes = [PLATFORMS.VST];

function ProductPage({
  localizedContent: { productPage: content, hubGlossary: hubContent },
  isError,
  onRetry,
  triggerProductOperationAction
}) {
  const { resourceId = '', resourceType = '' } = useParams();
  const { search } = useLocation();

  const [isDisabled, setDisabled] = useState(false);

  const domain = new URLSearchParams(search).get('domain');
  const resourceTypeIsValid = !!resourceType && acceptedResourceTypes.includes(resourceType.toUpperCase());
  const parametersAreValid = !!resourceId && resourceTypeIsValid && !!domain;

  useEffect(() => {
    const query = Object.fromEntries(new URLSearchParams(search));
    if (parametersAreValid) {
      triggerProductOperationAction({ resourceId, product: resourceType, ...query }, LTI_ACTIONS.OPEN);
    }
  }, [parametersAreValid, resourceId, resourceType, search]);

  const handleRetry = () => {
    setDisabled(true);

    if (onRetry && typeof onRetry === 'function') {
      onRetry();
      return;
    }

    window.location.reload();
  };

  const showError = () => (
    <ErrorView
      title={content.error_title}
      detail={content.error_details}
      iconType={types.INFO}
      disabled={isDisabled}
      onRetry={!isError ? null : handleRetry}
    />
  );

  const showLoadingSpinnerOrError = () => {
    if (!parametersAreValid || isError) {
      return showError();
    }

    return (
      <div className={styles.loadingSpinnerWrapper}>
        <PopoutPanelIconHeading type={types.LOADING} title="Loading" />
      </div>
    );
  };

  return (
    <div>
      <Helmet title={content.page_title} titleTemplate={hubContent.page_title_template} />
      {showLoadingSpinnerOrError()}
      <ResourceFormHidden />
    </div>
  );
}

const mapStateToProps = state => ({
  ...pick(state.hubResourceDownload, ['isError'])
});

const mapDispatchToProps = {
  triggerProductOperationAction: hubDownloadResource
};

export default compose(
  withLocalizedContent('productPage', 'hubGlossary'),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductPage);

ProductPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  isError: PropTypes.bool,
  onRetry: PropTypes.func,
  triggerProductOperationAction: PropTypes.func
};
