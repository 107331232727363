import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// Styles
import Button from '@oup/shared-front-end/src/components/Button';
import styles from './HubBreadcrumbs.scss';
// Components
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Link from '../Link/Link';
import HubLabel from '../HubLabel/HubLabel';

import isCourseReadyToLaunch from '../../structure/HubDashboardLayout/Utils/isCourseReadyToLaunch';

export default function HubBreadcrumbs({
  breadcrumbData: {
    id,
    title,
    name,
    action,
    actionTestHook,
    buttonText,
    showButton,
    selectedPath,
    showSubSideNavbar,
    level,
    getLink,
    path,
    pathname,
    item,
    hasIconOnFirstLevel,
    imgObj: { src, glyph, alt, svgBackgroundColour },
    customClassname = ''
  },
  hubContent
}) {
  const currentOrganisationLti = useSelector(state => state.identity.currentOrganisationLti);

  const notReadyForLaunch = !isCourseReadyToLaunch(item);

  return (
    <div className={styles.breadcrumbFlexContainer}>
      <div className={`${styles.breadcrumbContainer} ${styles[customClassname] || ''}`}>
        {(id || title) && (
          <div className={styles.breadcrumbStart}>
            <Link
              className={path === pathname ? styles.breadcrumbTitle : styles.breadcrumbTitleLink}
              disabled={path === pathname}
              to={showSubSideNavbar ? getLink : path}
            >
              <span>{title}</span>
              {id && <SVGIcon glyph={GLYPHS.ICON_RIGHT_THICK} />}
            </Link>
          </div>
        )}
        {item && (
          <div className={styles.hubBreadcrumbs}>
            {showSubSideNavbar ? (
              <b>{selectedPath}</b>
            ) : (
              <div className={styles.breadcrumb}>
                {(id || hasIconOnFirstLevel) && (
                  <div className={styles.hubBreadcrumbsImgContainer}>
                    {src ? (
                      <img src={src} alt={alt} />
                    ) : (
                      <div className={styles[svgBackgroundColour]}>
                        <SVGIcon glyph={glyph} />
                      </div>
                    )}
                  </div>
                )}
                {notReadyForLaunch && (
                  <div className={styles.comingSoonContainer}>
                    <HubLabel text={hubContent.hub_label_coming_soon} customClassName={styles.comingSoonLabel} />
                  </div>
                )}
                <div className={styles.hubBreadcrumbsText}>
                  <b id="HubBreadcrumbs__Course">{name}</b>
                  {level && <span>{level}</span>}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {!id && showButton && !currentOrganisationLti && (
        <div className={styles.action}>
          <Button
            onClick={action}
            text={buttonText}
            variant="filled"
            dataAttributes={{ testid: actionTestHook }}
            icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
          />
        </div>
      )}
    </div>
  );
}

HubBreadcrumbs.defaultProps = {
  id: '',
  name: '',
  level: ''
};

HubBreadcrumbs.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  level: PropTypes.string,
  breadcrumbData: PropTypes.object.isRequired,
  hubContent: PropTypes.object.isRequired
};
