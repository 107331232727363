import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Redux
import { closeOnboardingWizard } from '../../../redux/actions/hubUi';
import { clearWizardState } from '../../../redux/actions/classOnboardingWizard';
import { closeForm } from '../../../redux/reducers/classroomCreate.js';
// Components
import Button from '../../Button/Button';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import Link from '../../Link/Link';
import ClassCode from '../ClassCode/ClassCode';
import Tooltip from '../../Tooltip/Tooltip';
// Constants
import userRoles from '../../../globals/userRoles';
// Utils
import getClassLink from '../../../structure/HubDashboardLayout/Services/getClassLink';
import redirectToProgress from '../../../structure/HubDashboardLayout/Services/redirectToGradebookProgress';
import { owTooltipText } from '../../Tooltip/TooltipUtils';
import preventBodyScroll from '../../../utils/dom/preventBodyScroll';
// Styles
import wizardStyles from '../ClassOnboardingWizard.scss';
import animationStyles from '../animationUtils/animationStyles.scss';
import summaryStyles from './Summary.scss';

function Summary({
  orgId,
  userRole,
  classId,
  classNameValue,
  students,
  learningMaterials,
  hubContent,
  closeWizardAction,
  clearWizardStateAction,
  clearClassroomCreateStateAction,
  joiningCode = '',
  customOrgId = '',
  isOpen
}) {
  const { push } = useHistory();

  useEffect(() => {
    preventBodyScroll(isOpen);
  });

  const numberOfStudentsAdded = Object.keys(students).length;
  const numberOfLearningMaterialsAdded = learningMaterials.length;
  const userCanSeeClassJoiningCodeInfo =
    userRole === userRoles.TEACHER_ADMIN || userRole === userRoles.TEACHER || userRole === userRoles.ORG_ADMIN;

  const renderSuccessIcon = () => (
    <div className={summaryStyles.svgContainer}>
      <SVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE} className={summaryStyles.infoIcon} />
    </div>
  );

  const renderInfoIcon = () => (
    <div className={summaryStyles.svgContainer}>
      <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={summaryStyles.infoIcon} />
    </div>
  );

  const renderNumberOfStudentsText = () => {
    if (numberOfStudentsAdded === 0) {
      return hubContent.no_students;
    }

    if (numberOfStudentsAdded === 1) {
      return `1 ${hubContent.student}`;
    }

    return `${numberOfStudentsAdded} ${hubContent.students}`;
  };

  const renderLearningMaterialSummary = () => {
    if (numberOfLearningMaterialsAdded === 0) {
      return (
        <>
          <span className={summaryStyles.boldText}>{hubContent.no_materials}</span>
          <span> {hubContent.added_to_the_class}</span>
        </>
      );
    }

    const firstTitle = learningMaterials?.[0].title;

    if (numberOfLearningMaterialsAdded === 1) {
      return (
        <>
          <span className={summaryStyles.boldText}>{firstTitle}</span>
          <span> {hubContent.is_being_assigned}</span>
        </>
      );
    }

    if (numberOfLearningMaterialsAdded === 2) {
      return (
        <>
          <span className={summaryStyles.boldText}>{firstTitle}</span>
          <span> and </span>
          <span className={summaryStyles.boldText}>{hubContent.other}</span>
          <span> {hubContent.are_being_assigned}</span>
        </>
      );
    }

    return (
      <>
        <span className={summaryStyles.boldText}>{firstTitle}</span>
        <span> and </span>
        <span className={summaryStyles.boldText}>{`${numberOfLearningMaterialsAdded - 1} ${hubContent.others}`}</span>
        <span> {hubContent.are_being_assigned}</span>
      </>
    );
  };

  const handleGoToClassClick = e => {
    redirectToProgress(e, getClassLink(classId), orgId, push);
    closeWizardAction();
    clearWizardStateAction();
    clearClassroomCreateStateAction();
  };

  return (
    <>
      <div
        data-testid="ONBOARDING_WIZARD_SUMMARY_CONTAINER"
        className={`${wizardStyles.step} ${animationStyles.step} ${summaryStyles.container}`}
      >
        <div className={`${wizardStyles.stepContent} ${summaryStyles.contentContainer}`}>
          <div className={summaryStyles.contentColumn}>
            <h1>{`${classNameValue} ${hubContent.summary_title}`}</h1>
            {userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN ? (
              <div className={wizardStyles.classCodeContent}>
                <ClassCode joiningCode={joiningCode} customOrgId={customOrgId} hubContent={hubContent} />
                <Tooltip
                  title={owTooltipText(hubContent)}
                  className={`${wizardStyles.joinCodeTooltip} ${animationStyles.cancelOrSkip}`}
                >
                  <SVGIcon glyph={GLYPHS.ICON_HELP_CIRCLE} className={wizardStyles.infoIcon} />
                </Tooltip>
              </div>
            ) : null}

            <div className={summaryStyles.summaryDetails}>
              <div className={summaryStyles.detailsRow}>
                {numberOfStudentsAdded >= 1 && renderSuccessIcon()}
                {numberOfStudentsAdded === 0 && renderInfoIcon()}
                <div>
                  <span className={summaryStyles.boldText}>{renderNumberOfStudentsText()}</span>
                  <span>{` ${hubContent.added_to_the_class}`}</span>
                </div>
              </div>

              {numberOfStudentsAdded === 0 && userCanSeeClassJoiningCodeInfo ? (
                <div className={`${wizardStyles.infoContainer} ${summaryStyles.noStudentsInfoContainer}`}>
                  <span>
                    {userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN
                      ? hubContent.no_students_info_for_admins
                      : hubContent.no_students_info_for_teachers}
                  </span>
                  <Link
                    openInNewTab
                    to={
                      userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN
                        ? hubContent.support_page_give_students_a_join_code_link_admins
                        : hubContent.support_page_give_students_a_join_code_link
                    }
                    className={wizardStyles.link}
                  >
                    {hubContent.learn_more}
                    <SVGIcon glyph={GLYPHS.ICON_LINK} className={wizardStyles.linkGlyph} />
                  </Link>
                </div>
              ) : null}

              <div className={summaryStyles.detailsRow}>
                {numberOfLearningMaterialsAdded >= 1 && renderSuccessIcon()}
                {numberOfLearningMaterialsAdded === 0 && renderInfoIcon()}
                <div>{renderLearningMaterialSummary()}</div>
              </div>
            </div>
          </div>
          <div className={`${wizardStyles.imageContainer} ${summaryStyles.imageColumn}`}>
            <img src="/media/hub/class-onboarding/illustration-task-success-trophy.svg" alt="Task success trophy" />
          </div>
        </div>
      </div>

      <footer className={wizardStyles.footer}>
        <Button
          customClassName={wizardStyles.flexRight}
          text={`${hubContent.go_to} ${classNameValue}`}
          onClick={e => handleGoToClassClick(e)}
          dataTestId="ONBOARDING_WIZARD_GOTO_CLASS_BUTTON"
        />
      </footer>
    </>
  );
}

Summary.propTypes = {
  orgId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  classId: PropTypes.string.isRequired,
  classNameValue: PropTypes.string.isRequired,
  students: PropTypes.object.isRequired,
  learningMaterials: PropTypes.array.isRequired,
  hubContent: PropTypes.object.isRequired,
  closeWizardAction: PropTypes.func,
  clearWizardStateAction: PropTypes.func,
  clearClassroomCreateStateAction: PropTypes.func,
  joiningCode: PropTypes.string.isRequired,
  customOrgId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default connect(
  ({
    identity: { currentOrganisationId: orgId = '', role: userRole = '' } = {},
    classOnboardingWizard: {
      classId,
      classNameValue,
      userDetails: { students = {} } = {},
      learningMaterialDetails: { learningMaterials = [] } = {},
      joiningCode: { code: joiningCode = '' } = {}
    } = {},
    organisations
  }) => ({
    orgId,
    userRole,
    classId,
    classNameValue,
    learningMaterials,
    students,
    joiningCode,
    customOrgId: organisations?.data[orgId]?.customId
  }),
  {
    closeWizardAction: closeOnboardingWizard,
    clearWizardStateAction: clearWizardState,
    clearClassroomCreateStateAction: closeForm
  }
)(Summary);
