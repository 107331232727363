import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Helmet } from 'react-helmet';
import Heading from '@oup/shared-front-end/src/components/Heading';
// Components
import { compose } from 'recompose';
import { connect } from 'react-redux';
import LinkWithIcon from '../../../components/LinkWithIcon/LinkWithIcon';
import AssessmentTest from '../../../components/AssessmentTest/AssessmentTest';
import AssessmentTestFolder from '../../../components/AssessmentTest/AssessmentTestFolder';
import Resource from '../../../components/Resource/Resource';
import ResourceFormHidden from '../../HubProductLayout/Resources/ResourceFormHidden';
import OnlineTestModal from '../../../components/OnlineTestModal/OnlineTestModal';
import ResourceModal from '../../../components/ResourceModal/ResourceModal';
import OnlineTestPanel from '../../../panels/OnlineTestPanel';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
// Constants
import {
  ErrorModalType,
  RESOURCE_TYPE,
  HubLayoutConstants,
  RESOURCE_DOWNLOAD_TYPE,
  ResourceConstants,
  LTI_ACTIONS
} from '../../../globals/hubConstants';
import breakpoints from '../../../globals/breakpoints';
// Redux
import { hubDownloadResource } from '../../../redux/actions/hubResource';
import { setOnlineTestData } from '../../../redux/actions/onlineTestActions';
import {
  getAdaptedTests,
  setAdaptedTestsUpdated,
  setAdaptedTestsPolling,
  setAdaptedTestsNeedToBeUpdated
} from '../../../redux/actions/adaptedTests';
import { setCourses } from '../../../redux/actions/hubCourses';
// Services
import HubProductErrorModal from '../../HubProductLayout/HubProductErrorModal/HubProductErrorModal';
import getAssessmentHomeLink from '../Services/getAssessmentHomeLink';
import getTestData, { getTestPrintableResourceId, getTestDigitaleResourceId } from '../Services/getTestData';
import { hasHideableKeywords, hasLockableKeywords } from '../Utils/isLockedNode';
import getCourseLevel from '../../HubDashboardLayout/Services/getCourseLevel';
import setAdaptedTestsInCourses from '../../HubDashboardLayout/Utils/setAdaptedTestsInCourses';
import withBreakpoint from '../../../decorators/withBreakpoint';
import TestBuilderModal from '../../../components/TestBuilder/TestBuilderModal';
import getAssessmentCourseTitle from '../Utils/getAssessmentCourseTitle';
import downloadOrPreviewAssessment from '../Services/downloadOrPreviewAssessment';
import { featureIsEnabled } from '../../../globals/envSettings';
// Style
import styles from './AssessmentFolderPage.scss';
import assessmentStyles from '../../../components/AssessmentTest/AssessmentTest.scss';
import colors from '../../../globals/colors';

class AssessmentFolderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewTestAssessmentId: '',
      selectedToPreviewTest: null,
      resourceId: '',
      testBuilderAssessmentId: '',
      isNotDownloadableAssessmentId: null,
      errorModalType: ErrorModalType.NONE,
      onlineTestId: null,
      isOnlineTestOpen: false,
      adaptedTestPollingTimer: 30,
      adaptedTestInitialTimer: 10
    };
  }

  componentDidMount() {
    window.addEventListener('message', this._receiveMessage);
    this._getAllAdaptedTests();
  }

  componentDidUpdate() {
    const {
      setCoursesAction,
      hubAdaptedTests,
      courses,
      courseId,
      setAdaptedTestsUpdatedAction,
      setAdaptedTestsNeedToBeUpdatedAction
    } = this.props;
    if (hubAdaptedTests && hubAdaptedTests.ids && hubAdaptedTests.ids.length && !hubAdaptedTests.isPollingInProgress) {
      this._pollHubAdaptedTests(hubAdaptedTests.ids);
    }
    if (hubAdaptedTests.adaptedTestsNeedToBeUpdated) {
      setAdaptedTestsNeedToBeUpdatedAction(false);
      this._getAllAdaptedTests();
    }
    if (hubAdaptedTests && hubAdaptedTests.adaptedTests && !hubAdaptedTests.adaptedTestsRecentlyUpdated) {
      const coursesCopy = setAdaptedTestsInCourses(hubAdaptedTests.adaptedTests, courses, courses[courseId]);

      setAdaptedTestsUpdatedAction();
      setCoursesAction(coursesCopy);
    }
  }

  _getAllAdaptedTests = () => {
    const { getAdaptedTestsAction, setAdaptedTestsPollingAction, userId } = this.props;
    setAdaptedTestsPollingAction(true);
    getAdaptedTestsAction({ userId });
  };

  _receiveMessage = async e => {
    const { adaptedTestInitialTimer } = this.state;
    const { getAdaptedTestsAction, userId } = this.props;
    if (e.data.type === 'closeTestBuilderModal') {
      setTimeout(() => {
        getAdaptedTestsAction({ userId });
      }, adaptedTestInitialTimer * 1000);
      this._closeTestBuilderModal();
    }
  };

  _downloadAssessment = (testData, isAdaptedTest = false) => {
    const { downloadResource, courses, courseId, assessmentId } = this.props;
    const lorUserRole = HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR;
    this.setState(() => ({
      selectedToPreviewTest: testData
    }));
    const printableResourceId = getTestPrintableResourceId(testData, isAdaptedTest);
    downloadOrPreviewAssessment(
      downloadResource,
      LTI_ACTIONS.DOWNLOAD,
      printableResourceId,
      isAdaptedTest,
      lorUserRole,
      '',
      courses?.[courseId]?.courseCode,
      courseId,
      assessmentId
    );
  };

  _openPreviewTestModal = (assessmentId, testData = {}, isAdaptedTest = false) => {
    const { downloadResource, courses, courseId } = this.props;
    const lorUserRole = HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR;
    this.setState(() => ({
      previewTestAssessmentId: assessmentId,
      selectedToPreviewTest: testData
    }));

    const digitalResourceId = getTestDigitaleResourceId(testData, isAdaptedTest);
    if (digitalResourceId) {
      downloadOrPreviewAssessment(
        downloadResource,
        LTI_ACTIONS.PLAY,
        digitalResourceId,
        isAdaptedTest,
        lorUserRole,
        '',
        courses?.[courseId]?.courseCode,
        courseId,
        assessmentId
      );
    }
  };

  _closePreviewTestModal = () => {
    this.setState(() => ({
      previewTestAssessmentId: '',
      selectedToPreviewTest: null
    }));
  };

  _openResourceModal = resourceId => {
    const {
      courses,
      match: { params },
      downloadResource
    } = this.props;

    this.setState(() => ({
      resourceId
    }));

    const resources = get(courses, [params.courseId, 'resources']);

    const { PREVIEWABLE_FORMATS } = ResourceConstants;
    if (PREVIEWABLE_FORMATS.includes((resources[resourceId].format || '').toLowerCase())) {
      downloadResource({ ...resources[resourceId] }, 'play');
    }
  };

  _closeResourceModal = () => {
    this.setState(() => ({
      resourceId: ''
    }));
  };

  _openTestBuilderModal = testBuilderAssessmentId => {
    this.setState(() => ({
      testBuilderAssessmentId
    }));
    this._launchTestBuilder(testBuilderAssessmentId);
  };

  _closeTestBuilderModal = () => {
    this.setState(() => ({
      testBuilderAssessmentId: ''
    }));
  };

  _openNotDonwloadableModal = isNotDownloadableAssessmentId => {
    this.setState(() => ({
      isNotDownloadableAssessmentId,
      errorModalType: ErrorModalType.EXPIRED
    }));
  };

  _closeNotDownloadableModal = () => {
    this.setState(() => ({
      isNotDownloadableAssessmentId: null,
      errorModalType: ErrorModalType.NONE
    }));
  };

  _isLocked = (course, folder) => {
    let locked = true;
    if (folder.resources) {
      for (let i = 0; i < folder.resources.length; i += 1) {
        if (folder.resources[i].type === HubLayoutConstants.ASSESSMENT_TYPES.INTERACTIVE_FOLDER) {
          if (locked && course.interactiveFolders && course.interactiveFolders[folder.resources[i].id]) {
            locked = locked && hasLockableKeywords(course.interactiveFolders[folder.resources[i].id].keywords);
          } else if (folder.resources[i].type === HubLayoutConstants.ASSESSMENT_TYPES.RESOURCE) {
            if (locked && course.resources && course.resources[folder.resources[i].id]) {
              locked = locked && hasLockableKeywords(course.resources[folder.resources[i].id].keywords);
            }
          }
        }
      }
    }
    if (locked) {
      Object.keys(folder).forEach(key => {
        if (locked && folder[key] && folder[key].title) {
          locked = locked && this._isLocked(course, folder[key]);
        }
      });
    }
    return locked;
  };

  _launchTestBuilder = id => {
    const { HUB_RETURN_PATH: hubReturnPath } = HubLayoutConstants.TEST_BUILDER_CONSTANTS;
    const { downloadResource } = this.props;
    const testData = {
      resourceId: id,
      resourceAction: 'play',
      roles: HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR,
      resourceType: RESOURCE_TYPE.ASSESSMENT,
      isAdaptTestFlow: true,
      assessmentEntitlement: id,
      returnPath: hubReturnPath
    };
    downloadResource({ ...testData }, 'play');
  };

  _downloadResource = resource => {
    const { downloadResource } = this.props;
    downloadResource({ ...resource }, 'download');
  };

  _pollHubAdaptedTests = ids => {
    const { adaptedTestPollingTimer } = this.state;
    const { getAdaptedTestsAction, userId, setAdaptedTestsPollingAction } = this.props;
    setAdaptedTestsPollingAction(true);
    setTimeout(() => {
      getAdaptedTestsAction({ userId, ids });
    }, adaptedTestPollingTimer * 1000);
  };

  _openSetTest = (testId, test) => {
    const { courseId, courses, setOnlineTestDataAction } = this.props;
    const currentCourse = courses[courseId];
    const productAssessmentTitle = getAssessmentCourseTitle(currentCourse);

    this.setState({ onlineTestId: testId });
    this.setState({ isOnlineTestOpen: true });
    setOnlineTestDataAction({ test, productAssessmentTitle });
  };

  _closeSetTest = () => {
    this.setState({ isOnlineTestOpen: false });
  };

  render() {
    const {
      props: {
        courses,
        match: { params },
        hubContent,
        downloadIsLoading,
        breakpoint,
        resourceIdsDownloading
      },
      state: { previewTestAssessmentId, resourceId, selectedToPreviewTest },
      _openPreviewTestModal,
      _closePreviewTestModal,
      _openSetTest,
      _closeSetTest,
      _openResourceModal,
      _closeResourceModal
    } = this;

    const {
      onlineTestId,
      testBuilderAssessmentId,
      isOnlineTestOpen,
      isNotDownloadableAssessmentId,
      errorModalType
    } = this.state;
    const folder = get(params, 'folder');
    const course = get(courses, [params.courseId]);
    const assessmentFolders = get(course, ['assessmentFolders', params.assessmentId]);
    const subFolderTitle = get(assessmentFolders, [params.subFolder, 'title'], '');
    const folderTitle = folder ? folder.charAt(0).toUpperCase() + folder.slice(1) : '';

    const pageTitle = course
      ? `${get(course, ['title'])} ${getCourseLevel(get(course, ['properties']))} | ${folderTitle} | ${subFolderTitle}`
      : 'Loading';

    // 'testLicenceStudentsList' was previously mocked and only used to set the 'licenceStudentsList' state.
    // As part of EPS-10154 we removed the mocked data and temporarily disabled its dependencies
    // We will have a BE implementation that will populate 'testLicenceStudentsList' with real data. Untill then we keep it's value set as NULL
    const testLicenceStudentsList = null;
    if (course && course.interactiveFolders && onlineTestId && course.interactiveFolders[onlineTestId]) {
      course.interactiveFolders[onlineTestId].licenceStudentsList = testLicenceStudentsList;
    }

    const showHeading = featureIsEnabled('navigation-changes');
    const headingArgs = {
      text: hubContent.assessment,
      size: 'small',
      variant: 'h1'
    };

    return (
      <div className={styles.assessmentFolderPage}>
        <Helmet title={pageTitle} />
        {showHeading && <Heading {...headingArgs} />}
        {breakpoint !== breakpoints.XXS && (
          <span className={styles.backButton}>
            <LinkWithIcon
              isLhs
              id="TestCategoryBackLink"
              to={getAssessmentHomeLink(params.courseId, params.assessmentId, folder)}
              text={hubContent.button_back_to_assessment_text}
              glyph={GLYPHS.ICON_LEFT}
              aria-label="Previous page"
            />
          </span>
        )}
        <div className={styles.labelTitleFolder}>
          {get(assessmentFolders, [params.subFolder]) && assessmentFolders[params.subFolder].title}
        </div>
        <ul>
          {get(assessmentFolders, [params.subFolder]) &&
            assessmentFolders[params.subFolder].resources &&
            assessmentFolders[params.subFolder].resources.map(resource => {
              let assessment = {};
              const id = resource.id;
              if (resource.type === HubLayoutConstants.ASSESSMENT_TYPES.RESOURCE) {
                assessment = course.resources[resource.id];
                return (
                  !hasHideableKeywords(assessment.keywords) && (
                    <Resource
                      key={id}
                      resourceId={id}
                      openModal={_openResourceModal}
                      startedLicence
                      hasLicence
                      isExpired={assessment.keywords && hasLockableKeywords(assessment.keywords)}
                      hubContent={hubContent}
                      resource={assessment}
                      breakpoint={breakpoint}
                      downloadResource={this._downloadResource}
                      downloadIsLoading={downloadIsLoading}
                      resourceIdsDownloading={resourceIdsDownloading}
                    />
                  )
                );
              }
              if (resource.type === HubLayoutConstants.ASSESSMENT_TYPES.INTERACTIVE_FOLDER) {
                assessment = course.interactiveFolders[resource.id];
                return (
                  !hasHideableKeywords(assessment.keywords) && (
                    <div key={id}>
                      <AssessmentTest
                        assessmentId={id}
                        content={hubContent}
                        assessmentTest={assessment}
                        downloadAssessment={this._downloadAssessment}
                        openOnlineTest={this._openSetTest}
                        breakpoint={breakpoint}
                        openExpiredModal={() => {
                          this._openNotDonwloadableModal(assessment);
                        }}
                        openTestBuilderModal={() => {
                          this._openTestBuilderModal(assessment.digitalResourceId);
                        }}
                        locked={assessment.keywords && hasLockableKeywords(assessment.keywords)}
                        openModal={this._openPreviewTestModal}
                        isAdapted={false}
                      />
                      {assessment &&
                        assessment.adaptedTests &&
                        assessment.adaptedTests.length !== 0 &&
                        assessment.adaptedTests.map(item => (
                          <div className={assessmentStyles.adaptedTest} key={item.id}>
                            <SVGIcon className={assessmentStyles.adaptedTestArrow} glyph={GLYPHS.ICON_CHILD} />
                            {item.resource_title && (
                              <AssessmentTest
                                assessmentId={item.associated_resource_id}
                                assessmentTest={item}
                                title={item.resource_title}
                                locked={false}
                                content={hubContent}
                                openModal={this._openPreviewTestModal}
                                openOnlineTest={this._openSetTest}
                                breakpoint={breakpoint}
                                downloadAssessment={this._downloadAssessment}
                                openTestBuilderModal={() => {
                                  this._openTestBuilderModal(item.associated_resource_id);
                                }}
                                isAdapted
                              />
                            )}
                          </div>
                        ))}
                    </div>
                  )
                );
              }
              return '';
            })}
        </ul>
        <ul className={styles.assessmentTestFoldersList}>
          {get(assessmentFolders, [params.subFolder]) &&
            Object.keys(assessmentFolders[params.subFolder]).map(key => {
              let subFolder = {};
              if (assessmentFolders[params.subFolder][key] && assessmentFolders[params.subFolder][key].title) {
                subFolder = assessmentFolders[params.subFolder][key];
              }
              return (
                subFolder.title && (
                  <AssessmentTestFolder
                    key={key}
                    id={key || ''}
                    title={subFolder.title || ''}
                    locked={this._isLocked(course, subFolder)}
                    isLocked={this._isLocked}
                    tests={subFolder}
                    hubContent={hubContent}
                    course={course}
                    level={0}
                    openPreviewModal={_openPreviewTestModal}
                    downloadAssessment={this._downloadAssessment}
                    openPreviewResourceModal={this._openResourceModal}
                    downloadResource={this._downloadResource}
                    openOnlineTest={_openSetTest}
                    openTestBuilderModal={id => {
                      this._openTestBuilderModal(id);
                    }}
                    breakpoint={breakpoint}
                    downloadIsLoading={downloadIsLoading}
                    resourceIdsDownloading={resourceIdsDownloading}
                  />
                )
              );
            })}
        </ul>
        <ResourceFormHidden />

        <OnlineTestModal
          selectedTestId={previewTestAssessmentId}
          closeModal={_closePreviewTestModal}
          test={selectedToPreviewTest ? getTestData(selectedToPreviewTest) : {}}
          hubContent={hubContent}
          breakpoint={breakpoint}
          downloadIsLoading={downloadIsLoading}
        />

        <ResourceModal
          resourceId={resourceId}
          closeModal={_closeResourceModal}
          downloadResource={
            course?.resources[resourceId]?.downloadable === RESOURCE_DOWNLOAD_TYPE.NO ? null : this._downloadResource
          }
          resource={resourceId ? course.resources[resourceId] : {}}
          hubContent={hubContent}
          downloadIsLoading={downloadIsLoading}
          breakpoint={breakpoint}
        />
        {testBuilderAssessmentId ? (
          <TestBuilderModal
            resourceId={testBuilderAssessmentId}
            closeModal={this._closeTestBuilderModal}
            resource={testBuilderAssessmentId ? course.interactiveFolders[testBuilderAssessmentId] : {}}
            hubContent={hubContent}
          />
        ) : null}

        <OnlineTestPanel closePanel={() => _closeSetTest()} testId={onlineTestId} isOpen={isOnlineTestOpen} />

        <HubProductErrorModal
          isOpen={isNotDownloadableAssessmentId !== null}
          errorModalType={errorModalType}
          hubContent={hubContent}
          glyphIcon={GLYPHS.CALENICON_CALENDARDAR}
          glyphSize={thumbnailSizes.SECTION}
          glyphBackgroundColour={colors.HUB_BLUE}
          title={hubContent.assessment_not_available_modal_title}
          subTitle={hubContent.assessment_not_available_modal_subtitle}
          showLink={false}
          actionLabel={hubContent.done_button}
          closeExpiredModal={this._closeNotDownloadableModal}
        />
      </div>
    );
  }
}

AssessmentFolderPage.propTypes = {
  match: PropTypes.object,
  courses: PropTypes.object,
  assessmentId: PropTypes.string,
  courseId: PropTypes.string,
  courseCode: PropTypes.string,
  breakpoint: PropTypes.string,
  hubContent: PropTypes.object.isRequired,
  downloadResource: PropTypes.func.isRequired,
  downloadIsLoading: PropTypes.bool.isRequired,
  setOnlineTestDataAction: PropTypes.func,
  getAdaptedTestsAction: PropTypes.func,
  userId: PropTypes.string,
  hubAdaptedTests: PropTypes.object,
  setCoursesAction: PropTypes.func,
  setAdaptedTestsUpdatedAction: PropTypes.func,
  setAdaptedTestsPollingAction: PropTypes.func,
  setAdaptedTestsNeedToBeUpdatedAction: PropTypes.func,
  resourceIdsDownloading: PropTypes.object
};

AssessmentFolderPage.defaultProps = {
  courses: {},
  courseId: '',
  match: {}
};

const mapStateToProps = ({ hubResourceDownload, identity, hubAdaptedTests }) => ({
  downloadIsLoading: hubResourceDownload?.downloadIsLoading,
  resourceIdsDownloading: hubResourceDownload?.resourceIdsDownloading,
  userId: identity.userId,
  hubAdaptedTests
});

export default compose(
  withBreakpoint,
  connect(mapStateToProps, {
    getAdaptedTestsAction: getAdaptedTests,
    downloadResource: hubDownloadResource,
    setOnlineTestDataAction: setOnlineTestData,
    setCoursesAction: setCourses,
    setAdaptedTestsUpdatedAction: setAdaptedTestsUpdated,
    setAdaptedTestsNeedToBeUpdatedAction: setAdaptedTestsNeedToBeUpdated,
    setAdaptedTestsPollingAction: setAdaptedTestsPolling
  })
)(AssessmentFolderPage);
