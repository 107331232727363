import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWizard } from 'react-use-wizard';
import { connect } from 'react-redux';
// Utils
import animationTimeout from '../animationUtils/animationTimeout';
import preventBodyScroll from '../../../utils/dom/preventBodyScroll';
// Redux
import { getClassStudents } from '../../../redux/actions/classOnboardingWizard';
import { formStates } from '../../../redux/reducers/addStudentsToClassroom';
// Constants
import userRoles from '../../../globals/userRoles';
// Components
import Button, { buttonTypes } from '../../Button/Button';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import UserRepresentation from '../../UserRepresentation/UserRepresentation';
import AddStudentsPanel from '../../../routes/ClassroomPage/tabs/StudentsTab/panels/AddStudents/AddStudents';
import Link from '../../Link/Link';
import ClassCode from '../ClassCode/ClassCode';
// Styles
import studentStyles from './AddStudents.scss';
import wizardStyles from '../ClassOnboardingWizard.scss';
import animationStyles from '../animationUtils/animationStyles.scss';

function AddStudents({
  orgId,
  userRole,
  classId,
  className,
  hubContent,
  numberOfStudents = 0,
  formState,
  addStudentsRequestFailed,
  getClassStudentsAction,
  studentsData = {},
  joiningCode = '',
  customOrgId = '',
  isOpen
}) {
  const { nextStep, handleStep, isLoading } = useWizard();
  const [stepOut, setStepOut] = useState(false);
  const [isPanelOpen, togglePanel] = useState(false);

  useEffect(() => {
    if (formState === formStates.CONFIRMATION && addStudentsRequestFailed === false) {
      getClassStudentsAction({ orgId, classId });
    }
  }, [addStudentsRequestFailed]);

  useEffect(() => {
    preventBodyScroll(isOpen);
  });

  const loading = isLoading ? animationStyles.loading : '';
  const fadeOut = stepOut ? animationStyles.fadeOut : '';

  handleStep(async () => {
    setStepOut(true);
    await animationTimeout();
  });

  const handleAddStudentsClick = () => {
    togglePanel(true);
  };

  const getStudentsList = () =>
    Object.entries(studentsData).map(([userId, user]) => (
      <div
        key={userId}
        data-testid={`ONBOARDING_WIZARD_STUDENT_${userId}`}
        className={studentStyles.studentItemContainer}
      >
        <UserRepresentation
          firstName={user.firstname || ''}
          lastName={user.lastname || ''}
          identifier={user.email}
          role={user.roleName}
          locked={user.isLocked}
        />
      </div>
    ));

  const getClassCodeInfoContainer = () => {
    if (userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN) {
      return (
        <div className={`${wizardStyles.infoContainer} ${studentStyles.classCodeInfoContainer}`}>
          <p>
            {hubContent.class_code_info_for_org_admins_1}
            <span className={wizardStyles.boldText}>{hubContent.class_code.toLowerCase()}</span>
            {hubContent.class_code_info_for_org_admins_2}
          </p>
          <div className={wizardStyles.classCodeContent}>
            <ClassCode joiningCode={joiningCode} customOrgId={customOrgId} hubContent={hubContent} />
          </div>
          <Link
            openInNewTab
            to={hubContent.support_page_give_students_a_join_code_link_admins}
            className={wizardStyles.link}
          >
            {hubContent.learn_more_about_class_codes}
            <SVGIcon glyph={GLYPHS.ICON_LINK} className={wizardStyles.linkGlyph} />
          </Link>
        </div>
      );
    }

    if (userRole === userRoles.TEACHER) {
      return (
        <div className={`${wizardStyles.infoContainer} ${studentStyles.classCodeInfoContainer}`}>
          <p>
            {hubContent.class_code_info_for_teachers_1}
            <span className={wizardStyles.boldText}>{hubContent.class_code.toLowerCase()}.</span>
            {hubContent.class_code_info_for_teachers_2}
          </p>
          <Link openInNewTab to={hubContent.support_page_give_students_a_join_code_link} className={wizardStyles.link}>
            {hubContent.learn_more_about_class_codes}
            <SVGIcon glyph={GLYPHS.ICON_LINK} className={wizardStyles.linkGlyph} />
          </Link>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div
        data-testid="ONBOARDING_WIZARD_ADD_STUDENTS_CONTAINER"
        className={`${wizardStyles.step} ${studentStyles.container} ${animationStyles.step} ${fadeOut}`}
      >
        <div className={`${wizardStyles.stepContent} ${studentStyles.contentContainer}`}>
          <div className={studentStyles.contentColumn}>
            <h1>{`${hubContent.add_students_title} ${className}`}</h1>
            <p className={studentStyles.addStudentsInfo}>{`${hubContent.add_students_info}`}</p>
            <div className={studentStyles.buttonContainer}>
              <Button
                dataTestId="ONBOARDING_WIZARD_ADD_STUDENTS_BUTTON"
                text={hubContent.addStudents}
                onClick={handleAddStudentsClick}
                type={buttonTypes.SECONDARY}
              />
            </div>
            {numberOfStudents > 0 ? (
              <div className={studentStyles.studentsListContainer}>{getStudentsList()}</div>
            ) : null}
          </div>
          <div className={studentStyles.rightColumnContainer}>
            {getClassCodeInfoContainer()}
            <img src="/media/hub/class-onboarding/illustration-class-organizing.svg" alt="Class organizing" />
          </div>
        </div>
      </div>

      <footer className={wizardStyles.footer}>
        <Button
          customClassName={`${animationStyles.nextButton} ${loading}`}
          text={!loading ? hubContent.next : ''}
          disabled={isLoading || formState === formStates.SUBMITTING}
          onClick={nextStep}
          dataTestId="ONBOARDING_WIZARD_ADD_STUDENTS_NEXT_BUTTON"
        />
      </footer>

      <AddStudentsPanel
        isOpen={isPanelOpen}
        closePanel={() => togglePanel(!isPanelOpen)}
        onComplete={() => togglePanel(!isPanelOpen)}
        orgId={orgId}
        classId={classId}
      />
    </>
  );
}

AddStudents.propTypes = {
  orgId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  classId: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired,
  numberOfStudents: PropTypes.number.isRequired,
  studentsData: PropTypes.object,
  formState: PropTypes.string.isRequired,
  addStudentsRequestFailed: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  getClassStudentsAction: PropTypes.func,
  joiningCode: PropTypes.string,
  customOrgId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired
};

export default connect(
  ({
    identity: { currentOrganisationId: orgId = '', role: userRole = '' } = {},
    classOnboardingWizard: {
      classId = '',
      classNameValue: className = '',
      userDetails: { students: studentsData = {} } = {},
      joiningCode: { code: joiningCode = '' } = {}
    } = {},
    addStudentsToClassroom: { formState = '', requestFailed: addStudentsRequestFailed = null } = {},
    organisations: { data: organisationsData = {} } = {}
  }) => ({
    orgId,
    userRole,
    classId,
    className,
    numberOfStudents: Object.keys(studentsData).length,
    studentsData,
    formState,
    addStudentsRequestFailed,
    joiningCode,
    customOrgId: organisationsData[orgId]?.customId
  }),
  { getClassStudentsAction: getClassStudents }
)(AddStudents);
