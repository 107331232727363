import React from 'react';
import PropTypes from 'prop-types';
import { useWizard } from 'react-use-wizard';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import styles from './SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent }
}) {
  const { nextStep } = useWizard();

  return (
    <>
      <div
        data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_STAFF_ROLE_CHOOSES_PLACEMENT_TEST_CONTAINER"
        className={wizardStyles.step}
      >
        <div className={styles.stepContent}>
          <h1>{content.staff_role_chooses_placement_test_title}</h1>
          <h2>{content.staff_role_chooses_placement_test_subtitle}</h2>
          <ol className={styles.organizationSetupSteps}>
            <li>
              <p>{content.staff_role_chooses_placement_test_first_step_bold}</p>
              <p>{content.staff_role_chooses_placement_test_first_step}</p>
            </li>
            <li>
              <p>{content.staff_role_chooses_placement_test_second_step_bold}</p>
              <p>{content.staff_role_chooses_placement_test_second_step}</p>
            </li>
            <li>
              <p>{content.staff_role_chooses_placement_test_third_step_bold}</p>
              <p>{content.staff_role_chooses_placement_test_third_step}</p>
            </li>
          </ol>
          <TextLink to="#" target="_self">
            {content.staff_role_chooses_placement_test_learn_more_link_text}
          </TextLink>
        </div>
        <div className={wizardStyles.imageContainer}>
          <img src="/media/feedback/illustration-test.svg" alt="Illustration placement test organizing" />
        </div>
      </div>
      <footer className={wizardStyles.footer}>
        <Button
          dataAttributes={{ testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON' }}
          variant="filled"
          text={hubContent.next}
          disabled={false}
          onClick={() => nextStep()}
        />
      </footer>
    </>
  );
}

SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent(
  'selfRegisteredUserOnboardingWizard',
  'hubGlossary'
)(SelfRegisteredUserOnboardingWizardStaffRoleChoosesPlacementTest);
