import get from 'lodash.get';

const getTeacherLabel = (classes, classId, nrOfTeachers) => {
  if (nrOfTeachers > 1) {
    return `${nrOfTeachers} teachers`;
  }
  if (nrOfTeachers === 1 && get(classes[classId], ['teacherNameList', 'length'], 0) === 1) {
    return `Teacher: ${get(classes[classId], ['teacherNameList'], '')}`;
  }
  return null;
};

export default getTeacherLabel;
