import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboardButton from '../../CopyToClipboardButton/CopyToClipboardButton';
import styles from './ClassCode.scss';

function ClassCode({ joiningCode, customOrgId, hubContent }) {
  const classCode = joiningCode ? `${customOrgId}-${joiningCode}` : '';

  return (
    <div className={styles.classCodeContainer}>
      <span>{hubContent.class_code_label}</span>
      <span>
        <span className={styles.firstPartCode}>{customOrgId}</span>
        <span>-{joiningCode}</span>
      </span>
      <CopyToClipboardButton classCode={classCode} />
    </div>
  );
}

ClassCode.propTypes = {
  joiningCode: PropTypes.string.isRequired,
  customOrgId: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired
};

export default ClassCode;
