import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
// HOC
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink/TextLink';
import { WORD_OF_DAY_BANNER } from '@oup/shared-front-end/src/svg/oup/index';
import withLocalizedContent from '../../language/withLocalizedContent';
// Styles
import styles from './WordOfTheDay.scss';
// Constants
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
// Components
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

function playAudio(url) {
  new Audio(url).play();
}

function WordOfTheDay(props) {
  const {
    localizedContent: { hubGlossary: hubContent }
  } = props;
  const wordContent = {
    status: 'success',
    data: {
      entryLabel: 'empower',
      textEntryPreview: 'verb',
      entryUrl: 'https://www.oxfordlearnersdictionaries.com/coast_1',
      htmlEntryPreview: 'coast',
      dictionaryCode: 'english',
      entryId: 'coast_1',
      CEFRLevel: 'C1',
      wordListName: 'Oxford 3000',
      wordListUrl: 'https://www.oxfordlearnersdictionaries.com/wordlists/oxford3000-5000',
      pronunciations: [
        {
          pronunciationUrl: 'https://old.staging.idm.fr/media/english/uk_pron/c/coa/coast/coast__gb_1.mp3',
          lang: 'uk',
          dictionaryCode: 'english',
          entryId: 'coast_1'
        },
        {
          pronunciationUrl: 'https://old.staging.idm.fr/media/english/us_pron/c/coa/coast/coast__us_1.mp3',
          lang: 'us',
          dictionaryCode: 'english',
          entryId: 'coast_1'
        },
        {
          pronunciationUrl: 'https://old.staging.idm.fr/media/english/uk_pron/c/coa/coast/coast__gb_1.mp3',
          lang: 'uk',
          dictionaryCode: 'english',
          entryId: 'coast_1'
        },
        {
          pronunciationUrl: 'https://old.staging.idm.fr/media/english/us_pron/c/coa/coast/coast__us_1.mp3',
          lang: 'us',
          dictionaryCode: 'english',
          entryId: 'coast_1'
        },
        {
          pronunciationUrl: 'https://old.staging.idm.fr/media/english/uk_pron/c/coa/coast/coast__gb_1.mp3',
          lang: 'uk',
          dictionaryCode: 'english',
          entryId: 'coast_1'
        },
        {
          pronunciationUrl: 'https://old.staging.idm.fr/media/english/us_pron/c/coa/coast/coast__us_1.mp3',
          lang: 'us',
          dictionaryCode: 'english',
          entryId: 'coast_1'
        }
      ]
    }
  };

  const getPronunciationUrls = () => {
    const pronunciations = wordContent.data.pronunciations;
    const britishPronunciation = pronunciations.find(pron => pron.lang === 'uk');
    const americanPronunciation = pronunciations.find(pron => pron.lang === 'us');
    const britishUrl = britishPronunciation ? britishPronunciation.pronunciationUrl : null;
    const americanUrl = americanPronunciation ? americanPronunciation.pronunciationUrl : null;
    return { britishUrl, americanUrl };
  };

  const { britishUrl, americanUrl } = getPronunciationUrls();

  const emptyState = (
    <HubEmptyStateRestyle
      iconSrc={HubIllustrationConstants.WORD_OF_DAY_HEADER}
      iconAlt={HubIllustrationAltText.WORD_OF_DAY_HEADER}
      title={hubContent.word_of_the_day_empty_state_title}
      bodyText={hubContent.word_of_the_day_empty_state_subtitle}
    />
  );

  const wordOfTheDay = (
    <div>
      <span className={styles.srOnly}>{hubContent.word_of_the_day}</span>
      <WORD_OF_DAY_BANNER className={styles.wordHeader} alt={hubContent.word_of_the_day} />
      <div className={styles.content}>
        <div className={styles.word}>{wordContent.data.entryLabel}</div>
        <div className={styles.levelPill}>
          {wordContent.data.CEFRLevel ? <div className={styles.level}>{wordContent.data?.CEFRLevel}</div> : null}
          <div className={styles.category}>{wordContent.data.textEntryPreview}</div>
        </div>
        <div className={styles.pronunciations}>
          <Button
            text={hubContent.british}
            lable={hubContent.british}
            size="small"
            variant="outline"
            icon={{ component: <SVGIcon glyph={GLYPHS.ICON_AUDIO_RESOURCE} /> }}
            onClick={() => playAudio(britishUrl)}
          />
          <Button
            text={hubContent.american}
            label={hubContent.american}
            size="small"
            variant="outline"
            icon={{ component: <SVGIcon glyph={GLYPHS.ICON_AUDIO_RESOURCE} /> }}
            onClick={() => playAudio(americanUrl)}
          />
        </div>
        <TextLink leftIcon underline target="_blank" to={wordContent.data.entryUrl}>
          {hubContent.student_dashboard_meaning}
        </TextLink>
        <div className={styles.world}>{hubContent.student_dashboard_from_list}</div>
        <TextLink leftIcon underline target="_blank" to={wordContent.data.wordListUrl}>
          {wordContent.data.wordListName}
        </TextLink>
      </div>
    </div>
  );

  const wordOfTheDayContent =
    wordContent.status === 'success' ? (
      <div className={styles.wordOfTheDayContent}>{wordOfTheDay}</div>
    ) : (
      <div className={styles.emptyStateContainer}>{emptyState}</div>
    );

  return <div className={styles.wordOfTheDayArea}>{wordOfTheDayContent}</div>;
}

export default compose(withLocalizedContent('hubGlossary'))(WordOfTheDay);

WordOfTheDay.propTypes = {
  localizedContent: PropTypes.object.isRequired
};
