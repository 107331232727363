import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useWizard } from 'react-use-wizard';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import IconLeft from '@oup/shared-front-end/src/svg/oup/icon-left-24.svg';

import withLocalizedContent from '../../../language/withLocalizedContent';
import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import { showSelfSelectRoleModal } from '../../../redux/actions/hubUi';

function SelfRegisteredUserOnboardingWizardSuccess({
  localizedContent: {
    selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent,
    hubGlossary: hubContent
  },
  setIsOpenSelfRegisteredUserModal,
  showSelfSelectRoleModalAction
}) {
  const { previousStep } = useWizard();

  const handleDone = () => {
    setIsOpenSelfRegisteredUserModal(true);
    showSelfSelectRoleModalAction(false);
  };

  return (
    <>
      <div data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_SUCCESS_CONTAINER" className={wizardStyles.step}>
        <div className={wizardStyles.stepContent}>
          <h1>{selfRegisteredUserOnboardingWizardContent.self_registered_user_success_step_title}</h1>
          <h2>{selfRegisteredUserOnboardingWizardContent.self_registered_user_success_step_subtitle}</h2>
        </div>
        <div className={wizardStyles.imageContainer}>
          <img src="/media/feedback/illustration-success.svg" alt="Onboarding successful" />
        </div>
      </div>
      <footer className={classnames(wizardStyles.footer, wizardStyles.multipleButtonsFooter)}>
        <TextLink
          dataAttributes={{ testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_BACK_BUTTON' }}
          leftIcon={<IconLeft />}
          onClick={e => {
            e.preventDefault();
            previousStep();
          }}
        >
          {hubContent.button_back_text}
        </TextLink>
        <Button
          dataAttributes={{ testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_DONE_BUTTON' }}
          variant="filled"
          text={hubContent.done_button}
          disabled={false}
          onClick={handleDone}
        />
      </footer>
    </>
  );
}

SelfRegisteredUserOnboardingWizardSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  setIsOpenSelfRegisteredUserModal: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func.isRequired
};

export default compose(
  connect(null, { showSelfSelectRoleModalAction: showSelfSelectRoleModal }),
  withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary')
)(SelfRegisteredUserOnboardingWizardSuccess);
