/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import groupBy from 'lodash.groupby';
import get from 'lodash.get';
import pick from 'lodash.pick';
import { roleIsAtLeast } from '@oup/shared-node-browser/user';
import Heading from '@oup/shared-front-end/src/components/Heading';
import colors from '../../globals/colors';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import { featureIsEnabled } from '../../globals/envSettings';
import breakpoints from '../../globals/breakpoints';
// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
import withHubList from './withHubList';
import withBreakpoint from '../../decorators/withBreakpoint';
// Constants
import {
  getOrgStaffHeaderItems,
  getOrgStaffFooterActionButtons,
  HubLayoutConstants,
  MAX_PEOPLE,
  INSTANCE_ROLES,
  HubIllustrationConstants,
  HubIllustrationAltText
} from '../../globals/hubConstants';
import APP_CONSTANTS from '../../../sharedNodeBrowser/constants';
import userRoles from '../../globals/userRoles';
// Components
import HubListItem from './HubListItem';
import PaginationButtons from '../../components/PaginationButtons/PaginationButtons';
import PeopleFooter from '../HubClassLayout/People/PeopleFooter';
import DataRefresher from '../../components/DataRefresher/DataRefresher';
import SubSectionSkeletonLoader from '../../components/SkeletonLoader/SubSectionSkeletonLoader';
import HubFilterBar from '../../components/HubFilterBar/HubFilterBar';
import HubFilterBarSkeleton from '../../components/HubFilterBar/HubFilterBarSkeleton';

// Redux
import {
  setTeacherSelected,
  clearSelectedUser,
  setSelectAllTeachers,
  checkIfAllTeachersAreSelected,
  setAllTeachersSelected
} from '../../redux/reducers/organisationPage.reducer';
import { setPageOrgStudent, initialiseInstance, setFilter, setSort } from '../../redux/reducers/data/search.reducer';
// Services
import getPeopleCheckboxData from '../HubDashboardLayout/Services/getPeopleCheckboxData';
import withDataRecency from '../../dataRecency/withDataRecency';
import { peopleSortOptions, peopleFilterOptions } from '../../globals/hubSearchFilter';
import initialiseDefaultSearch from '../../utils/initialiseDefaultSearch';
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';

const EnhancedHubListPage = withHubList(HubListItem);
class HubListPageStaff extends Component {
  componentDidMount() {
    const { initialiseSearch, orgId } = this.props;
    initialiseDefaultSearch(INSTANCE_ROLES.ORG_STAFF, initialiseSearch, orgId);
  }

  componentWillUnmount() {
    const { setSelectAllTeachersAction } = this.props;

    setSelectAllTeachersAction([], false);
  }

  navigateToProfile = id => {
    const { push } = this.props;

    push(
      `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.STAFF}/${id}/learningMaterial`
    );
  };

  _getStaffContextName = () => {
    const {
      people,
      selectedTeacherIds,
      localizedContent: { productFinder: productFinderContent }
    } = this.props;
    return selectedTeacherIds.length === 1
      ? `${people[selectedTeacherIds[0]]?.firstname || ''} ${people[selectedTeacherIds[0]]?.lastname || ''}`
      : `${selectedTeacherIds.length} ${productFinderContent.teachers_text}`;
  };

  render() {
    const {
      orgTitle,
      orgAdminIds,
      teacherAdminIds,
      teacherIds,
      localizedContent: { mySchoolStaffTab, hubGlossary: hubContent },
      currentUsersList,
      breakpoint,
      selectedTeacherIds,
      areTeachersSelected,
      setTeacherSelectedAction,
      setSelectAllTeachersAction,
      checkIfAllTeachersAreSelectedAction,
      totalResults,
      page,
      setPageAction,
      userRole,
      userId,
      loadingResults,
      actions,
      userDropdownActions,
      isOrgModalOpen,
      orgId,
      initialiseSearch,
      peopleFilters,
      setFilterAction,
      setSortAction,
      currentOrganisationLti,
      setAllTeachersSelectedAction
    } = this.props;
    const classPageTitle = `${orgTitle} | ${hubContent.org_menu_staff}`;
    const canOrgadminRemoveTeachers = userRole === userRoles.ORG_ADMIN && !selectedTeacherIds.includes(userId);
    const sortValue = peopleFilters.sortStaff;

    const customFilterComponent = <HubFilterBarSkeleton />;

    const setFilterActionStaff = (valueName, value) => {
      setFilterAction('orgStaff', valueName, value);
    };

    const setSortActionStaff = sort => {
      setSortAction('orgStaff', sort[0]);
    };

    const filtersEmptyStateButtons = [
      {
        showButton: true,
        isPrimaryButton: true,
        text: `${hubContent.organization_page_filters_reset}`,
        action: () => {
          initialiseDefaultSearch(INSTANCE_ROLES.ORG_STAFF, initialiseSearch, orgId);
        },
        icon: GLYPHS.ICON_REFRESH
      }
    ];

    const headingArgs = {
      text: hubContent.org_menu_staff,
      size: 'small'
    };

    return (
      <div>
        <Helmet title={classPageTitle} />
        {featureIsEnabled('navigation-changes') && <Heading {...headingArgs} />}
        <DataRefresher
          loading={loadingResults}
          noSidePadding={featureIsEnabled('navigation-changes')}
          refreshData={() => {
            initialiseSearch(
              'orgStaff',
              false,
              {
                active: 'active' in peopleFilters.filtersStaff ? peopleFilters.filtersStaff.active : true,
                archived: 'archived' in peopleFilters.filtersStaff ? peopleFilters.filtersStaff.archived : true,
                invited: 'invited' in peopleFilters.filtersStaff ? peopleFilters.filtersStaff.invited : true,
                roles: [userRoles.TEACHER, userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN],
                isOrgStaffTab: true,
                orgId,
                hubPlatform: true
              },
              MAX_PEOPLE,
              'lastName:asc'
            );
          }}
        />
        <div>
          <HubFilterBar
            idPrefix="organizationStaffSearch"
            filterButtonText={breakpoint !== breakpoints.XXS ? hubContent.status_button : hubContent.filter_button}
            overlayLabelText={hubContent.status_button}
            breakpoint={breakpoint}
            filterOptions={peopleFilterOptions(
              'classPeopleSearch',
              [
                peopleFilters.filtersStaff.active,
                peopleFilters.filtersStaff.invited,
                peopleFilters.filtersStaff.archived
              ],
              setFilterActionStaff,
              hubContent
            )}
            sortOnChange={setSortActionStaff}
            sortOptions={peopleSortOptions('classPeopleSearch', sortValue, hubContent)}
            ariaControls="searchResults"
            sortValue={sortValue}
          />
        </div>
        {loadingResults ? (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED2}
            backgroundColor={colors.COLOR_WHITE}
            customEnhancedComponent={customFilterComponent}
          />
        ) : (
          <div>
            {totalResults ? (
              <>
                <EnhancedHubListPage
                  lists={[orgAdminIds, teacherAdminIds, teacherIds]}
                  headerItems={getOrgStaffHeaderItems(mySchoolStaffTab, hubContent, actions, currentOrganisationLti)}
                  currentUsersList={currentUsersList}
                  breakpoint={breakpoint}
                  hubContent={hubContent}
                  userRole={userRole}
                  selectedTeacherIds={selectedTeacherIds}
                  areTeachersSelected={areTeachersSelected}
                  onClick={this.navigateToProfile}
                  /** TODO: Refactor function */
                  checkboxData={getPeopleCheckboxData(
                    'TEACHER',
                    ...Array(6),
                    setTeacherSelectedAction,
                    selectedTeacherIds,
                    setSelectAllTeachersAction,
                    areTeachersSelected,
                    checkIfAllTeachersAreSelectedAction,
                    setAllTeachersSelectedAction
                  )}
                  totalResults={totalResults}
                  hasHeaderButtonAccess={userRole === userRoles.ORG_ADMIN}
                  actions={userDropdownActions}
                  dropdownType="orgStaff"
                  userId={userId}
                  currentOrganisationLti={currentOrganisationLti}
                />
                {totalResults > 10 ? (
                  <div className="gin-top2">
                    <PaginationButtons
                      idPrefix="orgStaffSearch"
                      value={page}
                      numberOfPages={Math.ceil(totalResults / 10)}
                      onClick={setPageAction}
                      aria={{ 'aria-controls': 'searchResults' }}
                    />
                  </div>
                ) : null}
                <PeopleFooter
                  widgetText={hubContent.chosen_text}
                  selectedUsers={selectedTeacherIds}
                  contextName={this._getStaffContextName()}
                  breakpoint={breakpoint}
                  actions={getOrgStaffFooterActionButtons(
                    hubContent,
                    userRole,
                    actions,
                    canOrgadminRemoveTeachers,
                    currentOrganisationLti
                  )}
                  isModalOpen={isOrgModalOpen}
                  context={APP_CONSTANTS.ORG_STAFF}
                />
              </>
            ) : (
              <HubEmptyStateRestyle
                iconSrc={HubIllustrationConstants.SEARCH}
                iconAlt={HubIllustrationAltText.SEARCH}
                title={hubContent.organization_page_filters_empty_state_title}
                bodyText={hubContent.organization_page_filters_empty_state_subtitle}
                btnFilledBase={
                  filtersEmptyStateButtons && filtersEmptyStateButtons.length > 0 ? filtersEmptyStateButtons[0] : null
                }
                btnOutlineBase={
                  roleIsAtLeast(userRoles.ORG_ADMIN, userRole)
                    ? {
                        isPrimaryButton: true,
                        text: hubContent.add_staff,
                        icon: GLYPHS.ICON_PLUS,
                        action: e => {
                          actions.addStaff(e, HubLayoutConstants.PEOPLE_PANEL_TYPES.ADD_STAFF, {
                            selectedUserId: '',
                            context: APP_CONSTANTS.ORG_STAFF
                          });
                        }
                      }
                    : null
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withLocalizedContent('mySchoolStaffTab', 'hubGlossary', 'productFinder'),
  withBreakpoint,
  withDataRecency('orgStaff'),
  connect(
    (state, { orgId, hubContent }) => {
      const myOrganization = get(state, ['organisations', 'data', [orgId]]);
      const staffids =
        featureIsEnabled('client-side-pagination') && state.search.orgStaff?.paginatedUserList
          ? Object.keys(state.search.orgStaff?.paginatedUserList)
          : state.search.orgStaff?.ids;
      const staffrole = featureIsEnabled('client-side-pagination')
        ? id => state.search.orgStaff.paginatedUserList[id].roleName
        : id => state.people.data[id].roleName;
      const {
        [userRoles.ORG_ADMIN]: orgAdminIds = [],
        [userRoles.TEACHER_ADMIN]: teacherAdminIds = [],
        [userRoles.TEACHER]: teacherIds = []
      } = groupBy(staffids, staffrole);

      const sortStaff = get(state, ['search', 'orgStaff', 'sort'], 'lastName:asc');
      const pageStaff = get(state, ['search', 'orgStaff', 'page'], 1);
      const filtersStaff = get(state, ['search', 'orgStaff', 'filters'], {});
      const totalResultsStaff = get(state, ['search', 'orgStaff', 'totalResults'], 0);
      const errorStaff = get(state, ['search', 'orgStaff', 'error'], false);

      return {
        orgTitle: get(myOrganization, 'name', hubContent.my_organization),
        ...pick(state.search.orgStaff, ['page', 'totalResults']),
        orgAdminIds,
        teacherAdminIds,
        teacherIds,
        people: state.people.data,
        currentUsersList: get(state, ['search', 'orgStaff', 'currentUsersList']),
        selectedTeacherIds: get(state, ['organisationPage', 'selectedTeacherIds'], []),
        areTeachersSelected: get(state, ['organisationPage', 'areTeachersSelected'], false),
        userRole: get(state, ['identity', 'role']),
        orgId: state.identity.currentOrganisationId,
        userId: state.identity.userId,
        loadingResults: state.search.orgStaff ? state.search.orgStaff.loading : false,
        isOrgModalOpen: state.hubUi.orgModalOpen,
        currentOrganisationLti: state.identity.currentOrganisationLti,
        peopleFilters: {
          sortStaff,
          pageStaff,
          filtersStaff,
          totalResultsStaff,
          errorStaff
        }
      };
    },
    {
      setTeacherSelectedAction: setTeacherSelected,
      setSelectAllTeachersAction: setSelectAllTeachers,
      checkIfAllTeachersAreSelectedAction: checkIfAllTeachersAreSelected,
      setAllTeachersSelectedAction: setAllTeachersSelected,
      clearSelectedUserAction: clearSelectedUser,
      initialiseSearch: initialiseInstance,
      setFilterAction: setFilter,
      setSortAction: setSort,
      setPageAction: page => setPageOrgStudent('orgStaff', page)
    }
  )
)(HubListPageStaff);

HubListPageStaff.propTypes = {
  orgTitle: PropTypes.string,
  orgAdminIds: PropTypes.array,
  teacherAdminIds: PropTypes.array,
  teacherIds: PropTypes.array,
  localizedContent: PropTypes.object,
  currentUsersList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  breakpoint: PropTypes.string,
  selectedTeacherIds: PropTypes.array,
  areTeachersSelected: PropTypes.bool,
  setTeacherSelectedAction: PropTypes.func,
  setSelectAllTeachersAction: PropTypes.func,
  checkIfAllTeachersAreSelectedAction: PropTypes.func,
  totalResults: PropTypes.number,
  page: PropTypes.number,
  setPageAction: PropTypes.func,
  userRole: PropTypes.string,
  orgId: PropTypes.string,
  initialiseSearch: PropTypes.func,
  userId: PropTypes.string,
  loadingResults: PropTypes.bool,
  userDropdownActions: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  push: PropTypes.func,
  isOrgModalOpen: PropTypes.bool,
  people: PropTypes.object,
  peopleFilters: PropTypes.object,
  setFilterAction: PropTypes.func,
  setSortAction: PropTypes.func,
  currentOrganisationLti: PropTypes.bool,
  setAllTeachersSelectedAction: PropTypes.func
};
