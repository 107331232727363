import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import RadioButtonList from '@oup/shared-front-end/src/components/RadioButtonList/RadioButtonList';
import TextLink from '@oup/shared-front-end/src/components/TextLink/TextLink';
import { ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import HubModalLayout from '../../HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../../Button/Button';
import styles from './SelfRegisteredUserWhatToDoNextModal.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { closeSelfRegisteredWizard, showSelfSelectRoleModal } from '../../../redux/actions/hubUi';

function SelfRegisteredUserWhatToDoNextModal({
  isOpen,
  onClose,
  onNext,
  showSelfSelectRoleModalAction,
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  closeSelfRegisteredWizardAction
}) {
  const modalRef = useRef(null);
  const selectionInitialState = '';
  const [selectedOption, setSelectedOption] = useState(selectionInitialState);
  const isDisabledNextBtn = !selectedOption;

  const handleOptionSelected = e => {
    setSelectedOption(e.target.value);
  };

  const handleNextBtn = () => {
    onNext(selectedOption);
  };

  const handleClose = () => {
    setSelectedOption(selectionInitialState);
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) handleClose();
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.outerWizardWarningModal} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout
        isOpen={isOpen}
        closeAction={onClose}
        focusOnFirstField={false}
        ariaLabel={content.self_registered_user_what_to_do_next_title}
      >
        <div
          data-testid="SELF_REGISTERED_USER_WHAT_TO_TO_NEXT_MODAL"
          className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`}
          aria-hidden={!isOpen}
          ref={modalRef}
        >
          <div className={styles.modalHeader}>
            <Button
              dataTestId="SELF_REGISTERED_USER_WHAT_TO_TO_NEXT_MODAL_CLOSE_BUTTON"
              text={hubContent.close}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              onClick={handleClose}
            />
            <h2 className={styles.title}>{content.self_registered_user_what_to_do_next_title}</h2>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.options}>
              <RadioButtonList
                type="outline"
                name="self-registered-user-options"
                legend="legend name test 1"
                noShadow
                checkedValue={[selectedOption]}
                radioGroup={[
                  {
                    id: `self-registered-user-radio-class`,
                    label: content.self_registered_user_what_to_do_next_label_class,
                    subLabel: content.self_registered_user_what_to_do_next_sublabel_class,
                    value: 'class'
                  },
                  {
                    id: `self-registered-user-radio-placementTest`,
                    label: content.self_registered_user_what_to_do_next_label_placement_test,
                    subLabel: content.self_registered_user_what_to_do_next_sublabel_placement_test,
                    value: ORG_REGISTRATION_CONTEXT.PLACEMENT
                  },
                  {
                    id: `self-registered-user-radio-code`,
                    label: content.self_registered_user_what_to_do_next_label_code,
                    subLabel: content.self_registered_user_what_to_do_next_sublabel_code,
                    value: 'code'
                  }
                ]}
                onChange={handleOptionSelected}
              />
            </div>
            <TextLink
              component={RouterLink}
              onClick={e => {
                e.preventDefault();
                showSelfSelectRoleModalAction(false);
                closeSelfRegisteredWizardAction();
              }}
            >
              {content.self_registered_user_what_to_do_next_continue_to_hub}
            </TextLink>
          </div>
          <div className={styles.modalFooter}>
            <TextLink
              dataTestId="SELF_REGISTERED_USER_WHAT_TO_TO_NEXT_MODAL_CANCEL_BUTTON"
              onClick={e => {
                e.preventDefault();
                handleClose();
              }}
            >
              {hubContent.cancel}
            </TextLink>
            <Button
              dataTestId="SELF_REGISTERED_USER_WHAT_TO_TO_NEXT_MODAL_CONFIRM_BUTTON"
              text={hubContent.next}
              type={buttonTypes.PRIMARY}
              onClick={handleNextBtn}
              disabled={isDisabledNextBtn}
            />
          </div>
        </div>
      </HubModalLayout>
    </div>
  );
}

SelfRegisteredUserWhatToDoNextModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default compose(
  withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'),
  connect(null, {
    showSelfSelectRoleModalAction: showSelfSelectRoleModal,
    closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
  })
)(SelfRegisteredUserWhatToDoNextModal);
