import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { HUB_SUPPORT_PLACEMENT_TESTS_EXTRA_TIME_URL } from '@oup/shared-node-browser/constants';
import RadioButtonList from '@oup/shared-front-end/src/components/RadioButtonList';
import Link from '../../Link/Link';
import styles from './PlacementTestExtraTime.scss';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import { setTestExtraTimePerItem } from '../../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../../language/withLocalizedContent';

function PlacementTestExtraTime({
  extraTimePerItem = 0,
  setExtraTimePerItemAction,
  editedPlacementTest,
  localizedContent: { placementTests: placementTestsContent }
}) {
  const radioButtonListArgs = {
    type: 'outline',
    name: 'extra-time-group',
    legend: 'legend name test',
    checkedValue: [extraTimePerItem],
    noShadow: true,
    radioGroup: [
      {
        id: 'extra-time-radio-0',
        label: placementTestsContent.create_placement_test_extra_time_label_0,
        subLabel: `${placementTestsContent.create_placement_test_extra_time_test_takes} ${
          editedPlacementTest.languageVariationCode
            ? placementTestsContent.create_placement_test_extra_time_tooltip_0_lang
            : placementTestsContent.create_placement_test_extra_time_tooltip_0
        }`,
        value: 0
      },
      {
        id: 'extra-time-radio-25',
        label: placementTestsContent.create_placement_test_extra_time_label_25,
        subLabel: `${placementTestsContent.create_placement_test_extra_time_test_takes} ${
          editedPlacementTest.languageVariationCode
            ? placementTestsContent.create_placement_test_extra_time_tooltip_25_lang
            : placementTestsContent.create_placement_test_extra_time_tooltip_25
        }`,
        value: 25
      },
      {
        id: 'extra-time-radio-50',
        label: placementTestsContent.create_placement_test_extra_time_label_50,
        subLabel: `${placementTestsContent.create_placement_test_extra_time_test_takes} ${
          editedPlacementTest.languageVariationCode
            ? placementTestsContent.create_placement_test_extra_time_tooltip_50_lang
            : placementTestsContent.create_placement_test_extra_time_tooltip_50
        }`,
        value: 50
      }
    ]
  };

  const handleOnChange = e => {
    const checkInputVal = radioButtonListArgs.radioGroup.filter(arrVal => arrVal === e.target.value).length > 0;
    setExtraTimePerItemAction(Number(e.target.value, checkInputVal));
  };

  return (
    <div className={styles.extraTimeContainer}>
      <h3>{placementTestsContent.create_placement_test_extra_time_subtitle}</h3>
      <p>{placementTestsContent.create_placement_test_extra_time_tooltip}</p>
      <RadioButtonList {...radioButtonListArgs} onChange={handleOnChange} />
      <Link openInNewTab to={HUB_SUPPORT_PLACEMENT_TESTS_EXTRA_TIME_URL} underlined className={styles.link}>
        {placementTestsContent.create_placement_test_extra_time_link}
        <SVGIcon glyph={GLYPHS.ICON_LINK} />
      </Link>
    </div>
  );
}

PlacementTestExtraTime.propTypes = {
  extraTimePerItem: PropTypes.number.isRequired,
  setExtraTimePerItemAction: PropTypes.func,
  editedPlacementTest: PropTypes.object,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      extraTimePerItem: placementTestSessionCreate.placementTest.extraTimePerItem,
      editedPlacementTest: placementTestSessionCreate.editedPlacementTest
    }),
    {
      setExtraTimePerItemAction: setTestExtraTimePerItem
    }
  )
)(PlacementTestExtraTime);
