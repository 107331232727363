import React from 'react';
import PropTypes from 'prop-types';
import { useWizard } from 'react-use-wizard';
import { connect } from 'react-redux';
// Redux
import { closeOnboardingWizard } from '../../redux/actions/hubUi';
import { clearWizardState } from '../../redux/actions/classOnboardingWizard';
import { closeForm } from '../../redux/reducers/classroomCreate.js';
// Components
import ProgressStepper from '../ProgressStepper/ProgressStepper';
import Button, { buttonTypes } from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon.js';
import Link from '../Link/Link';
// Styles
import wizardStyles from './ClassOnboardingWizard.scss';

function Header({
  closeWizardAction,
  closeWizardWithWarningPopup,
  hubContent,
  clearWizardStateAction,
  clearClassroomCreateStateAction
}) {
  const { activeStep, isLastStep, isFirstStep } = useWizard();
  const currentStep = activeStep + 1;

  const _renderStepHeading = () => (
    <div className={wizardStyles.topContainer}>
      {/* Header title */}
      <div className={wizardStyles.title}>
        <span>{hubContent.create_a_class} </span>
        {`${hubContent.step} ${currentStep} `}
      </div>

      {/* Help and Support */}
      <div className={wizardStyles.helpAndSupport}>
        <Link
          id="classOnboardingWizard-supportButton"
          dataTestId="ONBOARDING_WIZARD_SUPPORT_BUTTON"
          to={hubContent.help_and_support_link_to}
          target="_blank"
          role="button"
        >
          <SVGIcon glyph={GLYPHS.ICON_HELP_AND_SUPPORT} />
          <span className={wizardStyles.helpSupportText}>{hubContent.help_and_support}</span>
          <span className="a11y-hide">Help</span>
        </Link>
      </div>

      {/* Close button */}
      <Button
        text={hubContent.close}
        onClick={() => {
          if (isFirstStep) {
            closeWizardAction();
            clearWizardStateAction();
            clearClassroomCreateStateAction();
          } else {
            closeWizardWithWarningPopup();
          }
        }}
        type={buttonTypes.CLOSE_BORDER_GRAY}
        customClassName={wizardStyles.closeButton}
        dataTestId="ONBOARDING_WIZARD_CLOSE_BUTTON"
      />
    </div>
  );

  const _renderSummaryHeading = () => (
    <div className={wizardStyles.topContainer}>
      <Button
        text="Close"
        onClick={() => {
          closeWizardAction();
          clearWizardStateAction();
          clearClassroomCreateStateAction();
        }}
        type={buttonTypes.CLOSE_BORDER_GRAY}
        customClassName={wizardStyles.flexRight}
      />
    </div>
  );

  const _renderProgressStepper = () => (
    <div className={wizardStyles.progressContainer}>
      <ProgressStepper totalSteps={3} currentStep={currentStep} />
    </div>
  );

  return !isLastStep ? (
    <div className={wizardStyles.header}>
      {_renderStepHeading()}
      {_renderProgressStepper()}
    </div>
  ) : (
    <div className={wizardStyles.header}>{_renderSummaryHeading()}</div>
  );
}

Header.propTypes = {
  closeWizardAction: PropTypes.func.isRequired,
  clearWizardStateAction: PropTypes.func.isRequired,
  clearClassroomCreateStateAction: PropTypes.func.isRequired,
  closeWizardWithWarningPopup: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired
};

export default connect(null, {
  clearWizardStateAction: clearWizardState,
  clearClassroomCreateStateAction: closeForm,
  closeWizardAction: closeOnboardingWizard
})(Header);
