import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelfRegisteredUserOnboardingOrganizationSetupWizard from './SelfRegisteredUserOnboardingOrganizationSetupWizard';
import SelfRegisteredUserOnboardingChooseRoleWizard from './SelfRegisteredUserOnboardingChooseRoleWizard';

function SelfRegisteredUserOnboardingWizard({ isOpen, firstName, selectRole, selfSelectRoleModalOpen }) {
  const [firstWizardCompleted, setFirstWizardCompleted] = useState(false);
  const [secondWizardType, setSecondWizardType] = useState('');

  const getStaffSecondWizardTypeChoice = selectedOption => {
    setSecondWizardType(selectedOption);
    setFirstWizardCompleted(true);
  };

  return (
    <div>
      {!firstWizardCompleted ? (
        <SelfRegisteredUserOnboardingChooseRoleWizard
          isOpen={isOpen}
          firstName={firstName}
          selectRole={selectRole}
          selfSelectRoleModalOpen={selfSelectRoleModalOpen}
          getStaffSecondWizardTypeChoice={getStaffSecondWizardTypeChoice}
        />
      ) : (
        <SelfRegisteredUserOnboardingOrganizationSetupWizard secondWizardType={secondWizardType} />
      )}
    </div>
  );
}

SelfRegisteredUserOnboardingWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired,
  selfSelectRoleModalOpen: PropTypes.bool.isRequired
};

export default SelfRegisteredUserOnboardingWizard;
