import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { Helmet } from 'react-helmet';
// Redux
import { connect } from 'react-redux';
// HOC
import { compose } from 'recompose';
// Utils
import withBreakpoint from '../../decorators/withBreakpoint';
import withLocalizedContent from '../../language/withLocalizedContent';
// Constants
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
// Components
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import GradebookV2 from '../Gradebook/V2';
// Style
import styles from './MyProgress.scss';

function MyProgress({
  match: { params },
  isGradebookLoading = false,
  hasProductsInGradebook = false,
  localizedContent
}) {
  const hubContent = localizedContent.hubGlossary;
  return (
    <div className={styles.gradebookContainer}>
      <Helmet title={hubContent.my_progress_page_title} titleTemplate={hubContent.page_title_template} />
      <GradebookV2 params={params} myProgress />
      {!isGradebookLoading && !hasProductsInGradebook && !params.panelName && (
        <div>
          <HubEmptyStateRestyle
            iconSrc={HubIllustrationConstants.PROGRESS}
            iconAlt={HubIllustrationAltText.PROGRESS}
            title={hubContent.my_progress_empty_state_title}
            bodyText={hubContent.my_progress_empty_state_description}
          />
        </div>
      )}
    </div>
  );
}

export default compose(
  connect(
    ({ identity: { role: userRole }, gradebookClassReport: { products = {}, loading: isGradebookLoading } }) => ({
      userRole,
      products,
      isGradebookLoading,
      hasProductsInGradebook: !isEmpty(products)
    }),
    {}
  ),
  withLocalizedContent('hubGlossary'),
  withBreakpoint
)(MyProgress);

MyProgress.propTypes = {
  match: PropTypes.object.isRequired,
  hasProductsInGradebook: PropTypes.bool,
  isGradebookLoading: PropTypes.bool,
  localizedContent: PropTypes.object
};
