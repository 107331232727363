import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import styles from './TileLoader.scss';

function TileLoader({ customClassName }) {
  return (
    <div className={classnames(styles[customClassName], styles.tileSkeleton)}>
      {[...Array(4)].map((value, index) => (
        <SVGIcon key={index} glyph={GLYPHS.ICON_HUB_SKELETON_LOADING} />
      ))}
    </div>
  );
}

TileLoader.propTypes = {
  customClassName: PropTypes.string
};

export default TileLoader;
