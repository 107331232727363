import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWizard } from 'react-use-wizard';
import { connect } from 'react-redux';
// Utils
import animationTimeout from '../animationUtils/animationTimeout';
import { assignmentContexts } from '../../../globals/assignmentConstants';
import preventBodyScroll from '../../../utils/dom/preventBodyScroll';
// Services
import { featureIsEnabled } from '../../../globals/envSettings';
// Constants
import { productFinderFormStates } from '../../../redux/reducers/productFinder.reducer';
import { productInformationContext } from '../../../globals/hubConstants';
// Components
import Button, { buttonTypes } from '../../Button/Button';
import LicenceListItem from '../../ListItems/LicenceListItem/LicenceListItem';
import PopoutPanel from '../../PopoutPanel/PopoutPanel';
import AssignLearningMaterial from '../../../routes/panels/AssignLearningMaterial/AssignLearningMaterial';
import ProductFinderPanel from '../../ProductFinder/ProductFinderPanel';
// Styles
import styles from '../AddStudents/AddStudents.scss';
import materialStyles from './AssignProductOnboardingWizard.scss';
import wizardStyles from '../ClassOnboardingWizard.scss';
import animationStyles from '../animationUtils/animationStyles.scss';

function AssignProductOnboardingWizard({
  hubContent,
  classNameValue,
  breakpoint,
  classId,
  userDetails,
  userId,
  orgId,
  licenceItems,
  formState,
  role,
  isOpen
}) {
  const { nextStep, handleStep, isLoading } = useWizard();
  const [assignLearningMaterialPanelOpen, setAssignLearningMaterialPanelOpen] = useState(false);
  const [stepOut, setStepOut] = useState(false);

  const loading = isLoading ? animationStyles.loading : '';
  const fadeOut = stepOut ? animationStyles.fadeOut : '';
  const hasItems = licenceItems.length;

  handleStep(async () => {
    setStepOut(true);
    await animationTimeout();
  });

  useEffect(() => {
    preventBodyScroll(isOpen);
  });

  const getLearningMaterialItems = () => {
    const studentIdList = Object.keys(userDetails?.students || {});
    const teacherIdList = [userId];
    return licenceItems.map(licenceItem => {
      const id = licenceItem.productid;
      return (
        <div key={id} data-testid={`ONBOARDING_WIZARD_LEARNING_MATERIAL_${id}`}>
          <LicenceListItem
            hubContent={hubContent}
            currentUsersList={{ [id]: licenceItem }}
            classroomId={classId}
            licencesContext={assignmentContexts.CLASS_ONBOARDING_WIZARD}
            userAssignments={{ [id]: licenceItem }}
            classTeachersId={teacherIdList}
            classStudentsId={studentIdList}
            isTeacherAssignedToClass
            id={id}
            showStatus={false}
            showDropdown={false}
            userRole={role}
            isUserProfilePage
            breakpoint={breakpoint}
            productInformationContext={productInformationContext.PRODUCT_FINDER}
          />
        </div>
      );
    });
  };

  const _getClassOnboardingUsers = () => {
    const studentIdList = Object.keys(userDetails?.students || {});
    return { teacherIdList: [userId], studentIdList: [...studentIdList] };
  };

  return (
    <div data-testid="ONBOARDING_WIZARD_ASSIGN_LEARNING_MATERIALS_CONTAINER">
      <div
        className={`${wizardStyles.step} ${materialStyles.container} ${animationStyles.step} ${fadeOut} ${styles.container}`}
      >
        <div className={`${wizardStyles.stepContent} ${styles.contentContainer}`}>
          <div className={styles.contentColumn}>
            <h1>{hubContent.assign_learning_material_title.replace('{className}', classNameValue)}</h1>
            <div className={wizardStyles.infoContainer}>
              <p>
                <span>{hubContent.add_learning_material_info}</span>
              </p>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                onClick={() => {
                  setAssignLearningMaterialPanelOpen(true);
                }}
                dataTestId="ONBOARDING_WIZARD_ASSIGN_LEARNING_MATERIAL_BUTTON"
                type={buttonTypes.SECONDARY}
                text={hubContent.add_course_material}
              />
            </div>
            {hasItems ? <div className={styles.studentsListContainer}>{getLearningMaterialItems()}</div> : null}
          </div>

          <div className={wizardStyles.imageContainer}>
            <img src="/media/hub/class-onboarding/illustration-digital-library.svg" alt="Digital library" />
          </div>
        </div>
      </div>

      <PopoutPanel
        ariaLabel={hubContent.assign_material_to_class_panel_aria}
        isOpen={assignLearningMaterialPanelOpen}
        dataTestId="ASSIGN_LEARNING_MATERIAL_PANEL_ONBOARDING_WIZARD"
      >
        {featureIsEnabled('product-finder-refactor') ? (
          <ProductFinderPanel
            orgId={orgId}
            classId={classId}
            selectedUsers={_getClassOnboardingUsers()}
            contextName={classNameValue}
            onClose={() => {
              setAssignLearningMaterialPanelOpen(false);
            }}
            onComplete={() => {
              setAssignLearningMaterialPanelOpen(false);
            }}
            context={assignmentContexts.CLASS_ONBOARDING_WIZARD}
          />
        ) : (
          <AssignLearningMaterial
            context={assignmentContexts.CLASS_ONBOARDING_WIZARD}
            orgId={orgId}
            closePopoutAction={() => {
              setAssignLearningMaterialPanelOpen(false);
            }}
            onComplete={() => {
              setAssignLearningMaterialPanelOpen(false);
            }}
          />
        )}
      </PopoutPanel>

      <footer className={`${wizardStyles.footer} ${hasItems ? wizardStyles.footerRightJustify : ''}`}>
        <Button
          customClassName={`${animationStyles.nextButton} ${loading}`}
          text={!loading ? hubContent.next : ''}
          disabled={isLoading || formState === productFinderFormStates.SUBMITTING}
          onClick={nextStep}
        />
      </footer>
    </div>
  );
}

AssignProductOnboardingWizard.propTypes = {
  hubContent: PropTypes.object,
  classNameValue: PropTypes.string,
  classId: PropTypes.string,
  userDetails: PropTypes.object,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  licenceItems: PropTypes.object,
  role: PropTypes.string,
  breakpoint: PropTypes.string,
  formState: PropTypes.string,
  isOpen: PropTypes.bool.isRequired
};

export default connect(
  ({ classOnboardingWizard, identity, assignLearningMaterial }) => ({
    classNameValue: classOnboardingWizard.classNameValue,
    classNamePending: classOnboardingWizard.classNamePending,
    classCreated: classOnboardingWizard.classCreated,
    classId: classOnboardingWizard.classId,
    orgId: identity.currentOrganisationId,
    userId: identity.userId,
    userDetails: classOnboardingWizard.userDetails,
    licenceItems: classOnboardingWizard?.learningMaterialDetails?.learningMaterials || [],
    role: identity.role,
    formState: assignLearningMaterial.formState
  }),
  null
)(AssignProductOnboardingWizard);
