import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import Button from '@oup/shared-front-end/src/components/Button';
import style from './LicenceCounting.scss';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import SVGIcon, { GLYPHS } from '../../../SVGIcon/SVGIcon';

function LicenceCounting({
  localizedContent: { placementTests: placementTestsContent },
  setIsChooseTestTakerPanelOpen,
  setShowManageAddedStudentsPanel,
  setShowNumberOfPlacesForUnknownStudentsPanel,
  numberOfLicenses = 0,
  selectedLicenses = 0,
  newStudents = [],
  placesForUnknownStudents = 0
}) {
  const { setIsPrimaryButtonDisabled } = useWizard();

  const messageMap = {
    success: placementTestsContent?.placement_test_add_students_page_validation_text_success,
    warning: placementTestsContent?.placement_test_add_students_page_validation_text_warning
  };

  const [totalLicenses, setTotalLicenses] = useState(0);
  const [sessionSize, setSessionSize] = useState(0);
  useEffect(() => {
    setTotalLicenses(numberOfLicenses + selectedLicenses);
  }, [numberOfLicenses, selectedLicenses]);

  useEffect(() => {
    setSessionSize(newStudents.length + placesForUnknownStudents);
  }, [newStudents, placesForUnknownStudents]);

  useEffect(() => {
    setIsPrimaryButtonDisabled(sessionSize > totalLicenses);
  }, [sessionSize]);

  const checkRemainingLicences = () => {
    let statusState;

    if (sessionSize <= totalLicenses) {
      statusState = 'success';
    } else {
      statusState = 'warning';
    }
    return (
      <ValidationMessage state={statusState}>
        {messageMap[statusState]
          .replace('[usedLicences]', sessionSize)
          .replace('[remainingLicences]', numberOfLicenses)}
        <TextLink to="#">{placementTestsContent.placement_test_add_students_page_buy_more_link_text}</TextLink>
      </ValidationMessage>
    );
  };

  return (
    <div className={style.licenceCountingContainer}>
      <Button
        variant="outline"
        text={placementTestsContent.placement_test_add_students}
        icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
        onClick={() => setIsChooseTestTakerPanelOpen(true)}
      />
      <h4>
        <strong>{placementTestsContent.placement_test_add_students_page_session_size}</strong> {sessionSize}
      </h4>
      <div className={style.validationMessageContainer}>{checkRemainingLicences()}</div>
      <div className={style.studentsSelectorContainer}>
        <div
          className={style.studentsSelector}
          onClick={() => setShowManageAddedStudentsPanel(true)}
          onKeyDown={e => e.key === 'Enter' && setShowManageAddedStudentsPanel(true)}
          tabIndex={0}
          role="button"
        >
          <div className={style.segmentTitle}>
            <b>{placementTestsContent.placement_test_add_students_page_students_added}</b>
            <div className={style.numberOfStudentsInfo}>{newStudents.length}</div>
          </div>
          <SVGIcon glyph={GLYPHS.ICON_SMALL_BREADCRUMB_CHEVRON} className={style.chevronIcon} />
        </div>
        <hr />
        <div
          className={style.studentsSelector}
          onClick={() => setShowNumberOfPlacesForUnknownStudentsPanel(true)}
          onKeyDown={e => e.key === 'Enter' && setShowNumberOfPlacesForUnknownStudentsPanel(true)}
          tabIndex={0}
          role="button"
        >
          <div className={style.segmentTitle}>
            <b>{placementTestsContent.placement_test_add_students_page_places_for_unknown_students}</b>
            <div className={style.numberOfStudentsInfo}>{placesForUnknownStudents}</div>
          </div>
          <SVGIcon glyph={GLYPHS.ICON_SMALL_BREADCRUMB_CHEVRON} className={style.chevronIcon} />
        </div>
      </div>
    </div>
  );
}

LicenceCounting.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  setIsChooseTestTakerPanelOpen: PropTypes.func,
  setShowManageAddedStudentsPanel: PropTypes.func,
  setShowNumberOfPlacesForUnknownStudentsPanel: PropTypes.func,
  numberOfLicenses: PropTypes.number,
  selectedLicenses: PropTypes.number,
  newStudents: PropTypes.array,
  placesForUnknownStudents: PropTypes.number
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      numberOfLicenses: placementTestSessionCreate.placementTest.numberOfLicenses,
      selectedLicenses: placementTestSessionCreate.placementTest.selectedLicenses,
      newStudents: placementTestSessionCreate.placementTest.studentsList,
      placesForUnknownStudents: placementTestSessionCreate.placementTest.placesForUnknownStudents
    }),
    {}
  )
)(LicenceCounting);
