import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import HubModalLayout from '../../../../components/HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import animationStyles from '../../../../components/ClassOnboardingWizard/animationUtils/animationStyles.scss';
import { OPT_YL } from '../../../../components/PlacementTestOnBoardingWizard/utils/constants';
import { formatDate } from '../../../../components/PlacementTestOnBoardingWizard/utils/helpers';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import {
  closePlacementTestSessionInformationModal,
  openPlacementTestOnboardingWizard,
  openPlacementTestPanel
} from '../../../../redux/actions/hubUi';
import { continueEditedPlacementTest } from '../../../../redux/reducers/placementTestSessionCreate';
import { getContentPreviewRequest } from '../../../../redux/actions/contentPreview';
import { PLACEMENT_TEST_STATUS } from '../../../../globals/hubConstants';
import styles from './PlacementTestSessionInformationModal.scss';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function PlacementTestSessionInformationModal({
  isOpen,
  closeModal,
  placementTestSessionNameValue,
  placementTestSessionId,
  placementTestType,
  placementTestStatus,
  placementTest,
  testStartDate,
  testEndDate,
  showResultsToStudents,
  getContentPreviewRequestAction,
  openPlacementTestOnboardingWizardAction,
  continueEditedPlacementTestAction,
  openPlacementTestPanelAction,
  content,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent }
}) {
  const [modalSessionName, setModalSessionName] = useState('');
  const [buttonActionAndLabel, setButtonActionAndLabel] = useState({ label: '', action: () => {} });
  const [product, setProduct] = useState({});
  const isMyTasksPage = window.location.pathname.split('/').includes('myTasks');

  useEffect(() => {
    getContentPreviewRequestAction({
      source: 'am'
    });
  }, []);

  const renderProductCover = () => {
    let customClassName = styles.iconContainer;
    let iconGlyph = placementTestType === OPT_YL ? GLYPHS.ICON_YOUNG_LEARNERS_TEST : GLYPHS.ICON_DRAFT_PLACEMENT_TEST;
    if (!placementTestType) {
      customClassName = styles.productCover;
      iconGlyph = GLYPHS.ICON_PENCIL_IN_CIRCLE;
    }
    if (isMyTasksPage) iconGlyph = GLYPHS.ICON_DRAFT_PLACEMENT_TEST;
    return (
      <div className={customClassName}>
        <SVGIcon glyph={iconGlyph} />
      </div>
    );
  };

  const formatStartDate = () => {
    if (formatDate(new Date(), false) === formatDate(testStartDate, false)) {
      return `Today ${formatDate(testStartDate, false, true)}`;
    }
    return formatDate(testStartDate);
  };

  const renderValidationMessage = () => {
    if (isMyTasksPage) {
      return !showResultsToStudents ? (
        <ValidationMessage state="information">
          {placementTestsContent.placement_test_talk_to_your_teacher_about_your_results}
        </ValidationMessage>
      ) : null;
    }
    return (
      <ValidationMessage state="information">{placementTestsContent.placement_test_session_closed}</ValidationMessage>
    );
  };

  useEffect(() => {
    switch (placementTestStatus) {
      case PLACEMENT_TEST_STATUS.DRAFT:
        return setButtonActionAndLabel({
          label: hubContent.placement_test_session_continue_set_up,
          action: e => {
            closeModal();
            openPlacementTestOnboardingWizardAction();
            continueEditedPlacementTestAction({
              placementTestSessionId,
              placementTestSessionNameValue,
              placementTest
            });
            e.preventDefault();
          }
        });
      case PLACEMENT_TEST_STATUS.ACTIVE:
        if (isMyTasksPage)
          return setButtonActionAndLabel({ label: hubContent.placement_test_session_access_test, action: () => {} });
        return setButtonActionAndLabel({
          label: placementTestsContent.placement_test_ellipsis_menu_button_download_progress,
          action: () => {}
        });
      case PLACEMENT_TEST_STATUS.UPCOMING:
        return isMyTasksPage
          ? setButtonActionAndLabel({
              label: hubContent.done_button,
              action: e => {
                closeModal();
                e.preventDefault();
              }
            })
          : setButtonActionAndLabel({
              label: hubContent.placement_test_session_manage_session,
              action: e => {
                closeModal();
                openPlacementTestPanelAction();
                continueEditedPlacementTestAction({
                  placementTestSessionId: test._id,
                  placementTestSessionNameValue,
                  status: placementTestStatus,
                  joiningCode: placementTest.joiningCode,
                  placementTest
                });
                e.preventDefault();
              }
            });
      case PLACEMENT_TEST_STATUS.COMPLETED:
        if (isMyTasksPage) {
          return showResultsToStudents
            ? setButtonActionAndLabel({ label: hubContent.placement_test_session_view_progress, action: () => {} })
            : setButtonActionAndLabel({
                label: hubContent.done_button,
                action: () => {
                  closeModal();
                }
              });
        }
        return setButtonActionAndLabel({
          label: placementTestsContent.placement_test_ellipsis_menu_button_download_results,
          action: () => {}
        });
      case PLACEMENT_TEST_STATUS.MISSING:
        return setButtonActionAndLabel({
          label: hubContent.done_button,
          action: e => {
            closeModal();
            e.preventDefault();
          }
        });
      default:
        return setButtonActionAndLabel({ label: '', action: () => {} });
    }
  }, [placementTestStatus, showResultsToStudents]);

  useEffect(() => {
    if (placementTestType && content && content.length) {
      const sessionProduct = placementTestType === OPT_YL ? content[1] : content[0];
      setProduct(sessionProduct);
    }
  }, [content, placementTestType]);

  useEffect(() => {
    setModalSessionName(placementTestSessionNameValue);
  }, [isOpen]);

  return (
    <div className={`${styles.modalWrapper} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout
        isOpen={isOpen}
        closeAction={closeModal}
        ariaLabel={placementTestsContent.placement_test_session_close_modal_ariaLabel}
      >
        <div className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`} aria-hidden={!isOpen}>
          <div className={styles.modalHeader}>
            <Button
              text={hubContent.close}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              onClick={e => {
                e.stopPropagation();
                closeModal();
              }}
            />
          </div>

          <div className={styles.modalContent}>
            <div className={styles.sessionTitlesContainer}>
              <div className={styles.testTypeCover}>{renderProductCover()}</div>
              <div className={styles.productTitles}>
                <div className={styles.titleContainer}>
                  {placementTestStatus === 'ACTIVE' && (
                    <span className={`${styles.testStatus} ${styles[placementTestStatus]}`}>{placementTestStatus}</span>
                  )}
                  <p className={styles.title}>{modalSessionName}</p>
                </div>
                {placementTestType && <p className={styles.productTitle}>{product.title}</p>}
              </div>
            </div>
            {placementTestStatus === PLACEMENT_TEST_STATUS.COMPLETED && renderValidationMessage()}
            <div className={styles.sessionDates}>
              <span>
                <b>{placementTestsContent.placement_test_summary_start} </b>
                {testStartDate ? formatStartDate() : placementTestsContent.placement_test_session_time_not_set}
              </span>
              <span>
                <b>{placementTestsContent.placement_test_summary_end} </b>
                {testEndDate ? formatDate(testEndDate) : placementTestsContent.placement_test_session_time_not_set}
              </span>
              {isMyTasksPage && showResultsToStudents && placementTestStatus !== PLACEMENT_TEST_STATUS.MISSING ? (
                <span>
                  <b>{placementTestsContent.placement_test_score} </b>
                  52/120 (B2)
                </span>
              ) : null}
            </div>
          </div>

          <div
            className={`${styles.modalFooter} ${isMyTasksPage ? styles.modalFooterMyTasks : styles.modalFooterDefault}`}
          >
            <Button
              text={hubContent.cancel}
              type={isMyTasksPage ? buttonTypes.SECONDARY : buttonTypes.CANCEL}
              onClick={() => closeModal()}
            />
            <Button
              customClassName={animationStyles.nextButton}
              text={buttonActionAndLabel.label}
              type={buttonTypes.PRIMARY}
              onClick={buttonActionAndLabel.action}
            />
          </div>
        </div>
      </HubModalLayout>
    </div>
  );
}

PlacementTestSessionInformationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  placementTestSessionNameValue: PropTypes.string,
  placementTestSessionId: PropTypes.string,
  placementTestType: PropTypes.string,
  testStartDate: PropTypes.string,
  testEndDate: PropTypes.string,
  placementTestStatus: PropTypes.string,
  showResultsToStudents: PropTypes.bool,
  getContentPreviewRequestAction: PropTypes.func,
  openPlacementTestOnboardingWizardAction: PropTypes.func,
  continueEditedPlacementTestAction: PropTypes.func,
  openPlacementTestPanelAction: PropTypes.func,
  placementTest: PropTypes.object,
  content: PropTypes.object,
  localizedContent: PropTypes.object
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, contentPreview }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestType: placementTestSessionCreate.placementTest.placementTestType,
      placementTestStatus: placementTestSessionCreate.placementTest.status,
      placementTest: placementTestSessionCreate.placementTest,
      testStartDate: placementTestSessionCreate.placementTest.testStartDate,
      testEndDate: placementTestSessionCreate.placementTest.testEndDate,
      showResultsToStudents: placementTestSessionCreate.placementTest.showResultsToStudents,
      content: contentPreview.data || contentPreview.results
    }),
    {
      closeModal: closePlacementTestSessionInformationModal,
      getContentPreviewRequestAction: getContentPreviewRequest,
      openPlacementTestOnboardingWizardAction: openPlacementTestOnboardingWizard,
      continueEditedPlacementTestAction: continueEditedPlacementTest,
      openPlacementTestPanelAction: openPlacementTestPanel
    }
  )
)(PlacementTestSessionInformationModal);
