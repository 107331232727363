import React, { useEffect, useState } from 'react';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard } from 'react-use-wizard';
import { compose } from 'recompose';
import { ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import Select from '@oup/shared-front-end/src/components/Select/Select';
import { orgRoles } from '@oup/shared-node-browser/org';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import Button from '@oup/shared-front-end/src/components/Button';
import TextInput from '@oup/shared-front-end/src/components/TextInput/TextInput';
import classnames from 'classnames';
import actions from '../../../redux/actions';
import { getCurrentPlatform } from '../../../utils/platform';
import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import styles from './SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails.scss';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

const { SECONDARY_SCHOOL } = orgRoles;
function SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails({
  localizedContent: {
    selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent,
    hubGlossary: hubContent,
    countryCodes: countryCodesContent,
    registerOrgComponent: registerOrgComponentContent
  },
  branch = ORG_REGISTRATION_CONTEXT.CES,
  register,
  errors,
  validate,
  submitting,
  success,
  failure
}) {
  const getCreateOrgBranch = () =>
    branch === ORG_REGISTRATION_CONTEXT.PLACEMENT ? branch : ORG_REGISTRATION_CONTEXT.CES;
  const { nextStep, isLoading } = useWizard();
  const [formData, setFormData] = useState({
    name: '',
    countryCode: '',
    primaryEmail: '',
    webAddress: '',
    role: SECONDARY_SCHOOL
  });
  const [failureValidationMessage, setFailureValidationMessage] = useState(false);
  const isNextDisabled =
    submitting || isLoading || !Object.values(errors).length || Object.values(errors).some(val => val === true);

  const handleChange = key => value => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const handleBlur = key => () => {
    validate({ [key]: formData[key] });
  };

  const handleKeyUp = key => e => {
    setFormData({
      ...formData,
      [key]: e.target.value
    });
  };

  const getCreateOrgPayload = () => {
    const { name, ...restFormData } = formData;
    const payload = { ...restFormData, name, platformCode: getCurrentPlatform(), isLmsLtiTool: false };

    if (getCreateOrgBranch() === ORG_REGISTRATION_CONTEXT.PLACEMENT) {
      payload.placementCenter = true;
    }

    return payload;
  };

  const onNextStep = () => {
    register(getCreateOrgPayload(), getCreateOrgBranch());
  };

  useEffect(() => {
    if (success && !submitting) nextStep();
    if (failure && !submitting) setFailureValidationMessage(true);
  }, [success, failure, submitting]);

  return (
    <>
      <div
        data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_STAFF__ROLE_PROVIDES_ORGANIZATION_DETAILS_CONTAINER"
        className={classnames(wizardStyles.step, wizardStyles.centered)}
      >
        <div className={wizardStyles.stepContent}>
          {failureValidationMessage && (
            <div className="gin-bot3">
              <ValidationMessage state="error">
                {registerOrgComponentContent.validation_error_message}
              </ValidationMessage>
            </div>
          )}
          <h1>{selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_title}</h1>
          <h2 className={styles.subtitle}>
            {selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_subtitle}
          </h2>
          <div className={styles.orgSetupForm}>
            <TextInput
              id="name"
              label={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_name_label}
              placeholder={
                selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_name_placeholder
              }
              value={formData.name}
              required
              onChange={handleChange('name')}
              onKeyUp={handleKeyUp('name')}
              onBlur={handleBlur('name')}
              isValid={typeof errors.name === 'boolean' ? !errors.name : null}
              validationMessage={errors.name ? registerOrgComponentContent.name_error : ''}
            />
            <Select
              id="countryCode"
              name="countryCode"
              label={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_country_label}
              value={formData.countryCode}
              options={[
                {
                  value: '',
                  text: selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_country_placeholder
                },
                ...Object.entries(countryCodesContent).map(([value, text]) => ({ text, value }))
              ]}
              required
              onChange={handleChange('countryCode')}
              onBlur={handleBlur('countryCode')}
              isValid={typeof errors.countryCode === 'boolean' ? !errors.countryCode : null}
              validationMessage={errors.countryCode ? registerOrgComponentContent.country_error : ''}
            />
            <TextInput
              id="primaryEmail"
              name="primaryEmail"
              placeholder="primaryemail@organization.com"
              label={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_email_label}
              value={formData.primaryEmail}
              required
              autocomplete="email"
              onChange={handleChange('primaryEmail')}
              onKeyUp={handleKeyUp('primaryEmail')}
              onBlur={handleBlur('primaryEmail')}
              isValid={typeof errors.primaryEmail === 'boolean' ? !errors.primaryEmail : null}
              validationMessage={errors.primaryEmail ? registerOrgComponentContent.primary_email_error : ''}
            />
          </div>
        </div>
      </div>
      <footer className={wizardStyles.footer}>
        <Button
          className={`${animationStyles.nextButton} ${submitting ? animationStyles.loading : ''}`}
          dataAttributes={{ testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON' }}
          variant="filled"
          text={submitting ? '' : hubContent.next}
          disabled={isNextDisabled}
          onClick={onNextStep}
        />
      </footer>
    </>
  );
}

SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  branch: PropTypes.string,
  register: PropTypes.func,
  errors: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  failure: PropTypes.bool
};

export default compose(
  withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary', 'countryCodes', 'registerOrgComponent'),
  connect(
    state => ({
      ...pick(state.orgRegistration, ['errors', 'submitting', 'success', 'failure'])
    }),
    dispatch => ({
      register: (input, branch) => {
        dispatch(actions.registerOrgRequest(input, branch));
      },
      validate: input => {
        dispatch(actions.validateOrgInput(input));
      }
    })
  )
)(SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails);
