import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
// Constants
import { userRoles } from '@oup/shared-node-browser/user';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import breakpoints from '../../../globals/breakpoints';
// Services
import getCourseLink from '../Services/getCourseLink';
import getHomeLink from '../../HubProductLayout/Services/getHomeLink';
import getAssessmentHomeLink from '../../HubCourseAssessments/Services/getAssessmentHomeLink';
import getClassLink from '../Services/getClassLink';
import processUserRole from '../Services/processUserRole';
import getCourseLevel from '../Services/getCourseLevel';
import getOrganizationClassesLink from '../Utils/getOrganizationClassesLink';
import getOrganizationClassLink from '../Utils/getOrganizationClassLink';
// Components
import { GLYPHS } from '../../../components/Button/Button';
import HubBreadcrumbs from '../../../components/HubBreadcrumbs/HubBreadcrumbs';
import getCourseCoverImage from '../Utils/getCourseCoverImage';
import { featureIsEnabled } from '../../../globals/envSettings';

function HubBreadcrumbContainer({
  course,
  singleClass,
  hubContent,
  courseId,
  classId,
  assessmentId,
  showSubSideNavbar,
  subSideNavbarSelectedItem,
  openRedeemModal,
  openOnboardingWizard,
  openJoinClassWithCodeModal,
  pathname,
  userRole,
  organization,
  params,
  breakpoint
}) {
  const { PATH_NAMES } = HubLayoutConstants;

  // Course
  const courseLevel = getCourseLevel(get(course, ['properties']));
  const courseTitle =
    showSubSideNavbar && get(course, 'properties') ? `${course.title} ${courseLevel}` : hubContent.my_courses;
  // Class
  const classTitle = hubContent.my_classes;

  const classAction = () => {
    if (userRole === userRoles.LEARNER) {
      return openJoinClassWithCodeModal;
    }

    return openOnboardingWizard;
  };
  // Organization
  const orgSelectedPath = pathname.split('/');
  const organizationTitle = orgSelectedPath[orgSelectedPath.length - 1];
  const orgPageTabs = [PATH_NAMES.STUDENTS, PATH_NAMES.STAFF, PATH_NAMES.CLASSES, PATH_NAMES.LICENCES];
  const showOrgBreadcrumb =
    breakpoint === breakpoints.XXS &&
    PATH_NAMES.ORGANIZATION_PATH !== pathname &&
    pathname.split('/').some(item => orgPageTabs.includes(`/${item}`));
  // Organization Class
  const showClassOrgBreadcrumb =
    breakpoint === breakpoints.XXS &&
    getOrganizationClassLink(classId) !== pathname &&
    pathname.split('/').includes(classId);

  function getResourceOrAssessmentHomeLink(paths) {
    if (paths.includes(HubLayoutConstants.COURSE_FOLDERS.RESOURCE_FOLDER)) {
      return {
        id: courseId,
        title: courseTitle,
        name: course.title,
        level: courseLevel,
        getLink: getHomeLink(courseId, params.folder),
        path: PATH_NAMES.COURSES_PATH,
        item: course,
        action: openRedeemModal,
        buttonText: hubContent.enter_a_code_text,
        showButton: true,
        showSubSideNavbar,
        pathname,
        selectedPath: subSideNavbarSelectedItem,
        imgObj: {
          src: getCourseCoverImage(course),
          alt: course.title,
          svgBackgroundColour: 'courseBackgroundColour',
          glyph: GLYPHS.OUP_SYMBOL_REBRANDING
        }
      };
    }
    return {
      id: courseId,
      title: courseTitle,
      name: course.title,
      level: courseLevel,
      getLink: getAssessmentHomeLink(courseId, assessmentId, params.folder),
      path: PATH_NAMES.COURSES_PATH,
      item: course,
      action: openRedeemModal,
      buttonText: hubContent.enter_a_code_text,
      showButton: true,
      showSubSideNavbar,
      pathname,
      selectedPath: subSideNavbarSelectedItem,
      imgObj: {
        src: getCourseCoverImage(course),
        alt: course.title,
        svgBackgroundColour: 'courseBackgroundColour',
        glyph: GLYPHS.OUP_SYMBOL_REBRANDING
      }
    };
  }

  const getBreadcrumbData = () => {
    const processedUserRole = processUserRole(userRole, hubContent);
    const {
      COMMON: { showCreateClassButton = false, showJoinClassWithCodeCommonButton = false } = {}
    } = processedUserRole;
    const paths = pathname.split('/');

    switch (true) {
      case pathname.startsWith(PATH_NAMES.MY_PROGRESS):
        return {
          id: null,
          title: null,
          name: hubContent.my_progress,
          getLink: PATH_NAMES.MY_PROGRESS,
          path: PATH_NAMES.MY_PROGRESS,
          item: hubContent.my_progress,
          action: null,
          showButton: false,
          showSubSideNavbar,
          pathname,
          imgObj: {
            src: null,
            alt: null,
            svgBackgroundColour: 'courseBackgroundColour',
            glyph: GLYPHS.ICON_PROGRESS
          }
        };
      case pathname.startsWith(PATH_NAMES.COURSES_PATH):
        if (
          (paths.includes(HubLayoutConstants.COURSE_FOLDERS.ASSESSMENT_FOLDER) ||
            paths.includes(HubLayoutConstants.COURSE_FOLDERS.RESOURCE_FOLDER)) &&
          params.subFolder
        ) {
          return getResourceOrAssessmentHomeLink(paths);
        }
        return {
          id: courseId,
          title: courseTitle,
          name: course.title,
          level: courseLevel,
          getLink: getCourseLink(courseId),
          path: PATH_NAMES.COURSES_PATH,
          item: course,
          action: openRedeemModal,
          buttonText: featureIsEnabled('opt-main-features') ? hubContent.enter_a_code : hubContent.enter_a_code_text,
          showButton: true,
          showSubSideNavbar,
          pathname,
          selectedPath: subSideNavbarSelectedItem,
          imgObj: {
            src: getCourseCoverImage(course),
            alt: course.title,
            svgBackgroundColour: 'courseBackgroundColour',
            glyph: GLYPHS.OUP_SYMBOL_REBRANDING
          }
        };
      case pathname.startsWith(PATH_NAMES.CLASSES_PATH):
        return {
          id: classId,
          title: showSubSideNavbar ? singleClass.name : classTitle,
          name: singleClass.name,
          level: null,
          getLink: getClassLink(classId),
          path: PATH_NAMES.CLASSES_PATH,
          item: singleClass,
          action: classAction(),
          actionTestHook: userRole !== userRoles.LEARNER ? 'OPEN_ONBOARDING_WIZARD_BUTTON_MY_CLASSES' : '',
          buttonText: userRole === userRoles.LEARNER ? hubContent.join_class_with_code : hubContent.create_class,
          showButton: userRole === userRoles.LEARNER ? showJoinClassWithCodeCommonButton : showCreateClassButton,
          showSubSideNavbar,
          pathname,
          selectedPath: null,
          imgObj: {
            src: null,
            alt: null,
            svgBackgroundColour: 'classBackgroundColour',
            glyph: GLYPHS.ICON_CLASS
          }
        };
      case pathname.startsWith(`${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.SINGLE_CLASS_PATH}`):
        return {
          id: `${organization.name}-${classId}`,
          title: showClassOrgBreadcrumb ? singleClass.name : organization.name,
          name: singleClass.name,
          level: null,
          getLink: getOrganizationClassLink(classId),
          path: getOrganizationClassesLink(),
          item: singleClass,
          showButton: false,
          showSubSideNavbar: showClassOrgBreadcrumb,
          pathname,
          selectedPath: null,
          imgObj: {
            src: null,
            alt: null,
            svgBackgroundColour: 'classBackgroundColour',
            glyph: GLYPHS.ICON_CLASS
          }
        };
      // Just a dummy data for functional UI before breadcrumb refactoring.
      case pathname.startsWith(PATH_NAMES.ORGANIZATION_PATH):
        return {
          id: showOrgBreadcrumb && organization.name,
          title: showOrgBreadcrumb && organization.name,
          name: organization.name,
          item: organization.name,
          getLink: PATH_NAMES.ORGANIZATION_PATH,
          path: PATH_NAMES.ORGANIZATION_PATH,
          hasIconOnFirstLevel: true,
          showSubSideNavbar: showOrgBreadcrumb,
          pathname,
          selectedPath: organizationTitle,
          imgObj: {
            src: null,
            alt: null,
            svgBackgroundColour: 'orgBackgroundColour',
            glyph: GLYPHS.ICON_ORG
          },
          customClassname: 'orgBreadcrumb'
        };
      default:
        return null;
    }
  };

  const breadcrumbData = getBreadcrumbData();

  return breadcrumbData ? <HubBreadcrumbs breadcrumbData={breadcrumbData} hubContent={hubContent} /> : null;
}

HubBreadcrumbContainer.propTypes = {
  course: PropTypes.object,
  courseId: PropTypes.string,
  singleClass: PropTypes.object,
  classId: PropTypes.string,
  assessmentId: PropTypes.string,
  hubContent: PropTypes.object.isRequired,
  openRedeemModal: PropTypes.func,
  openOnboardingWizard: PropTypes.func,
  openJoinClassWithCodeModal: PropTypes.func,
  showSubSideNavbar: PropTypes.bool.isRequired,
  subSideNavbarSelectedItem: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  organization: PropTypes.object,
  params: PropTypes.object,
  breakpoint: PropTypes.string
};

HubBreadcrumbContainer.defaultProps = {
  singleClass: {},
  course: {},
  courseId: '',
  userRole: ''
};

export default HubBreadcrumbContainer;
