import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';

// Styles
import { ICON_RESOURCES } from '@oup/shared-front-end/src/svg/oup/index';
import Heading from '@oup/shared-front-end/src/components/Heading/Heading';
import styles from './LastOpened.scss';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
// Redux
import {
  openRedeemModal,
  openSelfRegisteredWizard,
  showSelfSelectRoleModal,
  openEnterCodeModal
} from '../../redux/actions/hubUi';
import { getRecentlyAccessedRequest } from '../../redux/actions/recentlyAccessed';
// Constants
import { HubLayoutConstants, HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import { featureIsEnabled } from '../../globals/envSettings';
// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
// Components
import LastOpenedItem from '../../components/LastOpened/LastOpenedItem/LastOpenedItem';
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';

function LastOpened({
  recentlyAccessedItems,
  showEnterCodeButton,
  openRedeemModalAction,
  getRecentlyAccessedAction,
  openEnterCodeModalAction,
  localizedContent: { hubGlossary: hubContent }
}) {
  const history = useHistory();
  useEffect(() => {
    getRecentlyAccessedAction();
  }, []);
  const emptyState = showEnterCodeButton ? (
    <HubEmptyStateRestyle
      iconSrc={HubIllustrationConstants.DIGITAL_LIBRARY}
      iconAlt={HubIllustrationAltText.DIGITAL_LIBRARY}
      title={hubContent.student_dashboard_new_accessed_products_title}
      bodyText={hubContent.student_dashboard_new_accessed_products_subtitle}
      btnFilledBase={{
        text: hubContent.enter_a_code,
        icon: GLYPHS.ICON_PLUS,
        action: () => (featureIsEnabled('opt-main-features') ? openEnterCodeModalAction() : openRedeemModalAction())
      }}
    />
  ) : (
    <HubEmptyStateRestyle
      iconSrc={HubIllustrationConstants.DIGITAL_LIBRARY}
      iconAlt={HubIllustrationAltText.DIGITAL_LIBRARY}
      title={hubContent.student_dashboard_never_accessed_products_title}
      bodyText={hubContent.student_dashboard_never_accessed_products_subtitle}
      btnFilledBase={{
        text: hubContent.my_courses,
        icon: GLYPHS.ICON_COURSE,
        action: () => history.push(HubLayoutConstants.PATH_NAMES.COURSES_PATH)
      }}
    />
  );

  const lastOpenedContent =
    recentlyAccessedItems.length > 0 ? (
      <>
        <Heading className={styles.heading} size="small" text="Last opened" variant="h1" />
        {Object.values(recentlyAccessedItems).map(item => (
          <LastOpenedItem
            key={item}
            title={item.title}
            subtitle={item.subtitle}
            to={item.url}
            imageOrIcon={
              item.type === 'product' ? (
                <img className={styles.image} src={item.imageUrl} alt={item.title} />
              ) : (
                <ICON_RESOURCES className={styles.icon} />
              )
            }
          />
        ))}
      </>
    ) : (
      <div className={styles.emptyStateContainer}>{emptyState}</div>
    );
  return <div className={styles.lastOpenArea}>{lastOpenedContent}</div>;
}

export default compose(
  connect(
    ({ recentlyAccessed = { items: [] }, hubCourses = { courses: {} } }) => {
      const hasCourses = !!Object.keys(hubCourses.courses).length;
      return {
        recentlyAccessedItems: recentlyAccessed.items,
        showEnterCodeButton: !hasCourses
      };
    },
    {
      openRedeemModalAction: openRedeemModal,
      showSelfSelectRoleModalAction: showSelfSelectRoleModal,
      openSelfRegisteredWizardAction: openSelfRegisteredWizard,
      getRecentlyAccessedAction: getRecentlyAccessedRequest,
      openEnterCodeModalAction: openEnterCodeModal
    }
  ),
  withLocalizedContent('hubGlossary')
)(LastOpened);

LastOpened.propTypes = {
  recentlyAccessedItems: PropTypes.object,
  showEnterCodeButton: PropTypes.bool,
  openRedeemModalAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  getRecentlyAccessedAction: PropTypes.func,
  openEnterCodeModalAction: PropTypes.func
};
