import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
// Constants
import { Helmet } from 'react-helmet';
// Services
import getCourseLink from '../../structure/HubDashboardLayout/Services/getCourseLink';
import { loadCourses } from '../../redux/actions/hubCourses';
// Components
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import TileContainer from '../../components/TileContainer/TileContainer';
import DataRefresher from '../../components/DataRefresher/DataRefresher';
import CourseTile from '../../components/CourseTile/CourseTile';
import { HubIllustrationConstants } from '../../globals/hubConstants.js';
import { featureIsEnabled } from '../../globals/envSettings';

function MyCourses({
  courses,
  courseIds,
  openRedeemModal,
  hubContent,
  breakpoint,
  areCoursesLoading,
  loadCourseData,
  currentOrganisationLti
}) {
  return (
    <div>
      <Helmet title={hubContent.my_courses_page_title} titleTemplate={hubContent.page_title_template} />
      <DataRefresher loading={areCoursesLoading} refreshData={() => loadCourseData()} />
      <TileContainer
        data={{
          isLoading: areCoursesLoading,
          ids: courseIds,
          iconSrc: HubIllustrationConstants.DIGITAL_LIBRARY,
          title: hubContent.no_courses_text,
          subtitle: hubContent.no_courses_access_code_text,
          showFirstEmptyStateButton: true,
          firstButtonText: featureIsEnabled('opt-main-features')
            ? hubContent.enter_a_code
            : hubContent.enter_a_code_text,
          firstButtonIcon: GLYPHS.ICON_PLUS,
          onClickFirstButton: openRedeemModal,
          isFirstButtonPrimary: true,
          getLink: getCourseLink,
          items: courses,
          Tile: CourseTile
        }}
        hubContent={hubContent}
        breakpoint={breakpoint}
        currentOrganisationLti={currentOrganisationLti}
      />
    </div>
  );
}

MyCourses.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  courses: PropTypes.object.isRequired,
  courseIds: PropTypes.array.isRequired,
  openRedeemModal: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  areCoursesLoading: PropTypes.bool.isRequired,
  loadCourseData: PropTypes.func,
  currentOrganisationLti: PropTypes.bool
};

const mapStateToProps = ({ identity: { currentOrganisationLti } }) => ({ currentOrganisationLti });

const mapDispatchToProps = {
  loadCourseData: loadCourses
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyCourses);
