/* eslint-disable import/prefer-default-export */
import { Link as RouterLink } from 'react-router-dom';
import { userRoles } from '@oup/shared-node-browser/user';
import getClassContext from '../../HubClassLayout/Utils/getClassContext';
import { isDev, isLocal, isTest } from '../../../globals/envSettings';
import userHasInteractiveAssessment from '../../../utils/userHasInteractiveAssessment';
import store from '../../../redux/store';
import getProgressLink from '../../HubDashboardLayout/Services/getProgressLink';
import getClassLink from '../../HubDashboardLayout/Services/getClassLink';
import getOrganizationClassLink from '../../HubDashboardLayout/Utils/getOrganizationClassLink';
import { HubLayoutConstants, SINGLE_CLASS_PAGES_CONTEXT } from '../../../globals/hubConstants';

const getClassLinks = (classId, orgId, key, context) => {
  const pathnames = HubLayoutConstants.PATH_NAMES;
  const links = {
    progress: {
      link:
        context === SINGLE_CLASS_PAGES_CONTEXT.CLASS_CONTEXT
          ? getProgressLink(getClassLink(classId), orgId)
          : getProgressLink(getOrganizationClassLink(classId), orgId),
      matcher: `${pathnames.SINGLE_CLASS_PATH}/${classId}${pathnames.PROGRESS}`
    },
    courseMaterials: {
      link:
        context === SINGLE_CLASS_PAGES_CONTEXT.CLASS_CONTEXT
          ? `${getClassLink(classId)}/classwork`
          : `${getOrganizationClassLink(classId)}/classwork`,
      matcher: `${pathnames.SINGLE_CLASS_PATH}/${classId}${pathnames.CLASSWORK}`
    },
    assessment: {
      link:
        context === SINGLE_CLASS_PAGES_CONTEXT.CLASS_CONTEXT
          ? `${getClassLink(classId)}${pathnames.ASSESSMENT}`
          : `${getOrganizationClassLink(classId)}${pathnames.ASSESSMENT}`,
      matcher: `${pathnames.SINGLE_CLASS_PATH}/${classId}${pathnames.ASSESSMENT}`
    },
    people: {
      link:
        context === SINGLE_CLASS_PAGES_CONTEXT.CLASS_CONTEXT
          ? `${getClassLink(classId)}${pathnames.PEOPLE}`
          : `${getOrganizationClassLink(classId)}${pathnames.PEOPLE}`,
      matcher: `${pathnames.SINGLE_CLASS_PATH}/${classId}${pathnames.PEOPLE}`
    }
  };

  return links[key];
};

const getClassesLinksData = ({ content, pathname, options: { classId, orgId, userRole, roleCustomizations } }) => {
  const context = getClassContext(pathname, classId);
  const {
    SINGLE_CLASS: {
      showProgressButton = false,
      showPeopleButton = false,
      showClassworkButton = false,
      showClassAssessmentButton = false
    } = {}
  } = roleCustomizations;
  const canShowAssessments =
    userRole === userRoles.LEARNER ||
    isLocal() ||
    isDev() ||
    isTest() ||
    userHasInteractiveAssessment(store.getState().hubCourses.courses);
  const links = [];

  if (showProgressButton) {
    const progressLinks = getClassLinks(classId, orgId, 'progress', context);

    links.push({
      to: progressLinks.link,
      component: RouterLink,
      textPrimary: content.progress,
      ariaAttributes: { current: !!pathname.match(progressLinks.matcher) }
    });
  }
  if (showClassworkButton) {
    const courseMaterialsLinks = getClassLinks(classId, orgId, 'courseMaterials', context);

    links.push({
      to: courseMaterialsLinks.link,
      component: RouterLink,
      textPrimary: content.course_material,
      ariaAttributes: { current: pathname.includes(courseMaterialsLinks.matcher) }
    });
  }
  if (canShowAssessments && showClassAssessmentButton) {
    const assessmentLinks = getClassLinks(classId, orgId, 'assessment', context);

    links.push({
      to: assessmentLinks.link,

      component: RouterLink,
      textPrimary: content.assessment,
      ariaAttributes: { current: pathname.includes(assessmentLinks.matcher) }
    });
  }
  if (showPeopleButton) {
    const peopleLinks = getClassLinks(classId, orgId, 'people', context);

    links.push({
      to: peopleLinks.link,
      component: RouterLink,
      textPrimary: content.people,
      ariaAttributes: { current: pathname.includes(peopleLinks.matcher) }
    });
  }

  return links;
};

export { getClassesLinksData };
