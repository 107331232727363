import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Components
import { Wizard } from 'react-use-wizard';
import Header from './Header';
import CreateAClass from './CreateAClass/CreateAClass';
import AddStudents from './AddStudents/AddStudents';
import AssignProductOnboardingWizard from './AssignProductOnboardingWizard/AssignProductOnboardingWizard';
import Summary from './Summary/Summary';
import ClassOnboardingWarningModal from './ClassOnboardingWarningModal/ClassOnboardingWarningModal.js';
import preventBodyScroll from '../../utils/dom/preventBodyScroll';
// Styles
import styles from './ClassOnboardingWizard.scss';

function ClassOnboardingWizard({ isOpen, hubContent, breakpoint }) {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  const closeWizardWithWarningPopup = () => {
    setWarningModalOpen(true);
  };

  const content = (
    <div className={styles.wizardContainer} data-testid="ONBOARDING_WIZARD_CONTAINER">
      <Wizard header={<Header closeWizardWithWarningPopup={closeWizardWithWarningPopup} hubContent={hubContent} />}>
        <CreateAClass
          closeWizardWithWarningPopup={closeWizardWithWarningPopup}
          hubContent={hubContent}
          isOpen={isOpen}
        />
        <AddStudents hubContent={hubContent} isOpen={isOpen} />
        <AssignProductOnboardingWizard breakpoint={breakpoint} hubContent={hubContent} isOpen={isOpen} />
        <Summary hubContent={hubContent} isOpen={isOpen} />
      </Wizard>
      <ClassOnboardingWarningModal
        isOpen={isWarningModalOpen}
        content={hubContent}
        closeWarningModal={() => setWarningModalOpen(false)}
      />
    </div>
  );

  useEffect(() => {
    preventBodyScroll(isOpen);
  }, [isOpen]);

  return isOpen ? content : null;
}

ClassOnboardingWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hubContent: PropTypes.object.isRequired,
  breakpoint: PropTypes.string
};

export default ClassOnboardingWizard;
